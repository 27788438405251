import "./bootstrap.scss"

import React from "react";
import FecomercioPage from "./FecomercioPage";
import FFADigitalPage from "./FFADigitalPage";

export default function EtapaInicial() {
    return (
        <>
            { process.env.REACT_APP_COMPANY_NAME === 'fecomercio' && <FecomercioPage /> }

            { process.env.REACT_APP_COMPANY_NAME !== 'fecomercio' && <FFADigitalPage /> }

            {/*{ process.env.REACT_APP_COMPANY_NAME !== 'fecomercio' && (*/}
            {/*    <Form>*/}
            {/*        <Container>*/}
            {/*            <Box>*/}
            {/*                <Card>Análise preliminar</Card>*/}
            {/*                <Interacao>*/}
            {/*                    <p>95% das empresas no Brasil, pagam ou já pagaram impostos indevidamente e ou/ a maior.*</p>*/}
            {/*                </Interacao>*/}

            {/*                <Text>*/}
            {/*                    <p>*/}
            {/*                        Empresário, receba em até 24hs uma <b>"Análise Técnica"</b> da sua*/}
            {/*                        empresa, realizada por nossa equipe multidisciplinar{" "}*/}
            {/*                        <span>SEM NENHUM CUSTO FINANCEIRO!</span>*/}
            {/*                    </p>*/}
            {/*                </Text>*/}

            {/*                <Input>*/}
            {/*                    <span className="clock">*/}
            {/*                        <LuClock3 /> É RÁPIDO, LEVA MENOS DE 1 MINUTO*/}
            {/*                    </span>*/}
            {/*                    <Form.Item*/}
            {/*                        name="cnpj"*/}
            {/*                        rules={[*/}
            {/*                            {*/}
            {/*                                required: true,*/}
            {/*                                message: "Por favor, digite o CNPJ da sua empresa!",*/}
            {/*                            },*/}
            {/*                        ]}*/}
            {/*                    >*/}
            {/*                        <InputComponent*/}
            {/*                            onChange={(e) => setCnpj(e.target.value)}*/}
            {/*                            placeholder={"Digite o CNPJ da sua empresa"}*/}
            {/*                            mask={"99.999.999/9999-99"}*/}
            {/*                            value={cnpj}*/}
            {/*                        />*/}
            {/*                    </Form.Item>*/}
            {/*                    <p>*/}
            {/*                        <small>*/}
            {/*                            <FcLock /> Dados protegidos | Lei n° 13.709/2018(LGPD)*/}
            {/*                        </small>*/}
            {/*                    </p>*/}
            {/*                </Input>*/}

            {/*                <ButtonComponent*/}
            {/*                    onClick={() => getDadosCnpj(cnpj)}*/}
            {/*                    text={loading ? "Carregando..." : "INICIAR"}*/}
            {/*                    icon={<BsArrowRight />}*/}
            {/*                    disabled={loading}*/}
            {/*                />*/}

            {/*                <hr />*/}

            {/*                /!*{ process.env.REACT_APP_COMPANY_NAME === 'fecomercio' && <LegacyCode /> }*!/*/}

            {/*                <CompanyFooter />*/}
            {/*            </Box>*/}
            {/*        </Container>*/}
            {/*    </Form>*/}
            {/*) }*/}
        </>
    );
}
