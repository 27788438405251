import { Select } from "./style";

export default function SelectComponent({
    value,
    options,
    style,
    onChange,
    defaultValue,
    name,
}) {
    return (
        <Select
            value={value}
            style={style}
            onChange={onChange}
            defaultValue={defaultValue}
            name={name}
        >
            {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))}
        </Select>
    );
}
