import { BsArrowRight } from "react-icons/bs";
import ButtonComponent from "../../common/component/Button";
import CardComponent from "../../common/component/Card";
import SelectComponent from "../../common/component/Select";
import { Box, BoxStyle, Container, Card } from "./styled";
import { useLocation, useNavigate } from "react-router-dom";
import Etapas from "../../common/component/Steps";
import { useEffect, useState } from "react";
import { Form } from "antd";
import { Progress } from "antd";
import { pathPrefix } from "../../data/pathPrefix";
import useQuery from "../../hooks/useQuery";

export default function Step05() {
    const location = useLocation();
    const navigate = useNavigate();
    const [regimeTributario, setRegimeTributario] = useState({});
    const { project_id } = location?.state || {};
    const queryParams = useQuery()
    const classesOptions = {
        0: 'item1',
        1: 'item2',
        2: 'item3'
    };
    const [years, setYears] = useState([])

    useEffect(() => {
        const currentYear = new Date().getFullYear()

        const years = []
        for (let i = currentYear - 2; i <= currentYear; i++) {
            years.push(i)
        }

        setYears(years)
    }, [setYears]);

    const handleFaturaAnoChange = (e) => {
        const { name, value } = e.target

        setRegimeTributario(prev => ({
            ...prev,
            [name]: value
        }));

        const elementos = document.querySelectorAll('.' + classesOptions[years.indexOf(parseInt(name) + 1)]);

        if (elementos.length > 0) {
          elementos.forEach(elemento => {
            if (elemento.tagName.toLowerCase() === 'button') {
              elemento.style.display = 'flex';
            } else {
              elemento.style.display = 'unset';
            }
          });
        } else {
          console.log('Nenhum elemento encontrado com a classe desejada.');
        }
    };

    const options = [
        { value: "0", label: "Selecione" },
        { value: "SIMPLES NACIONAL", label: "Simples Nacional" },
        { value: "LUCRO PRESUMIDO", label: "Lucro presumido" },
        { value: "LUCRO REAL", label: "Lucro real" },
    ];

    const onFinish = (values) => {
        const answers = Object.keys(regimeTributario).map(each => {
            return { type: 'text', label: `Regime ${each}`, value: regimeTributario[each] }
        })

        const prefix = pathPrefix()

        navigate(
            {
                pathname: prefix + '/informe/tributario-bruto',
                search: queryParams.toString()
            },
            {
                state: {
                    project_id,
                    questionario: answers
                }
            })
    };

    return (
        <Form
            onFinish={(e) => {
                onFinish(e);
            }}
        >
            <Container>
                <Box>
                    <Card>Questionário Preliminar</Card>
                    <BoxStyle>
                        <Etapas />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                margin: " 0",
                            }}
                        >
                            <Progress
                                percent={10}
                                showInfo={false}
                                strokeColor={{
                                    "0%": "#50c863",
                                    "100%": "#50c863",
                                }}
                                trailColor={"#e5e5e5"}
                                style={{ width: "100%", marginBottom: "1rem", height: "0px" }}
                            />
                            <p>Progresso</p>

                            <CardComponent
                                texto={"Informe o Regime Tributário da sua empresa"}
                            />
                        </div>

                        {years.map((ano, index) => {
                            return (
                                <div key={index} className={classesOptions[index]} style={{ display: index === 0 ? "unset" : "none" }}>
                                    <h3>
                                        EXERCÍCIO {ano}
                                        <b>*</b>
                                    </h3>
                                    <Form.Item
                                        name={`faturamento${ano}`}
                                        rules={[
                                            {
                                                required: true,
                                                message: `Informe o faturamento bruto de ${ano}`,
                                            },
                                        ]}
                                    >
                                        <SelectComponent
                                            onChange={handleFaturaAnoChange}
                                            placeholder={"Selecione"}
                                            value={regimeTributario}
                                            options={options}
                                            name={ano}
                                            style={{
                                                color: "black",
                                                height: "70px",
                                                border: "1px solid #000",
                                                borderRadius: "6px",
                                            }}
                                            required
                                        />
                                    </Form.Item>
                                </div>
                            );
                        })}

                        <ButtonComponent
                            className="item3"
                            type="submit"
                            text={"PRÓXIMO"}
                            icon={<BsArrowRight />}
                        />
                    </BoxStyle>
                </Box>
            </Container>
        </Form>
    );
}
