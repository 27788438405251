import styled from 'styled-components';
import ReactInputMask from "react-input-mask";

export const InputMask = styled(ReactInputMask)`
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    height: 48px;
    padding: 22px;
    border-radius: 5px;
    font-size: 18px;
    font-family: 'inter', sans-serif;
    outline: none !important;
    border-color: transparent;
`
