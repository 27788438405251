import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0 25px;
  box-sizing: border-box;
  align-items: center;
  background-color: #2c2a1f;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  max-width: 800px;
  flex-direction: column;
  align-items: center;

`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ac8d4c;
  border-radius: 0 0 10px 10px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
`;

export const BoxStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: white;
  gap: 25px;
  box-sizing: border-box;
`;

export const ImportantInfo = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  gap: 10px;
  text-align: justify;
  font-family: "Inter", sans-serif;
  line-height: 1.5;
  box-sizing: border-box;
  padding: 0 10px;
   h3 {
    font-weight: 400;
  }
    `;

export const CardCenter = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 40px;
`;


export const CardImportante = styled.div`
  display: flex;
  width: 40%;
  min-width: 172px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(127,105,59, 0.20) ;
  color: #AC8D4C;
  font-size: 18px;
  font-weight: bold;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 5px;
  svg  {
    width: 70px;
    height: 100%;
  }
`;
