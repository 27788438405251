import "bootstrap/dist/js/bootstrap.min";

import React, { useState } from 'react'
import { Form } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InputComponent from "../../common/component/Input";
import {Footer} from "./style";
import { pathPrefix } from "../../data/pathPrefix";
import useQuery from "../../hooks/useQuery";

export const FecomercioPage = () => {
    const [isLoading, setLoading] = useState(false)
    const [cnpj, setCnpj] = useState("");
    const navigate = useNavigate();
    const queryParams = useQuery()

    const getDadosCnpj = (cnpj) => {
        var cnpjTratado = cnpj.replace(/\D/g, "");
        setLoading(true);

        axios.post(
            "https://cnpj.biz/api/v2/empresas/cnpj",
            { cnpj: cnpjTratado },
            {
                headers: {
                    Authorization: `Bearer ${atob("dUllY1RvZXZuMXUwYWpCMTV0eHhMdzVKRjhsREhJcTFKVmNVWTdxRXEwRG1CNHNyOHVwSG92M0pmUnpxçklv".slice(0, -4))}`,
                },
            }
        )
        .then((resposta) => {
            const prefix = pathPrefix()

            if (resposta.data.cnpj === cnpjTratado)
                navigate(
                    {
                        pathname: prefix + "/informacoes",
                        search: queryParams.toString()
                    },
                    {
                        state: { dados: resposta.data },
                    },
                );
            else alert("CNPJ não encontrado");
        })
        .catch(() => {
            alert("Erro ao buscar dados do CNPJ");
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <div className="use_bootstrap">
            {/* <!-- Primeira Section --> */}
            <div className="container primeira-section py-5" id="inicio">
                <div className="row">
                    <div className="col-lg-6 p-4" style={{ marginTop: "5% !important", marginBottom: "5% !important" }}>
                        {/* <!-- Conteúdo da coluna esquerda --> */}
                        <h2 id="title">95% das empresas no Brasil,
                            pagam ou já pagaram impostos indevidos e ou a maior.*</h2>
                        <p id="title-description" style={{ color: "#be9b53" }} className="p-mob">
                            Empresário, cadastre-se e receba uma <b className="text-decoration-underline">análise técnica</b>
                            realizada pelos nossos especialistas.
                            (<span className="text-decoration-underline fst-italic" style={{ color: "#C89633" }}>zero custo</span>)
                        </p>
                        <p style={{ color: "#9a7b3e", fontSize: "18px", marginTop: "16px" , marginBottom: "60px", lineHeight: "24px"  }} className="p-desk">
                            Empresário, cadastre-se em nossa plataforma e <b style={{ textDecoration: "underline" }}>receba em até
                            24hrs</b> uma "Análise Técnica" da sua empresa realizada por nossa equipe multidisciplinar (advogados e
                            contabilistas), somados a tecnologia tributária, SEM NENHUM CUSTO FINANCEIRO!
                        </p>
                        <br />
                        <form className="form-inline justify-content-md-center position-relative d-flex">
                            <Form.Item
                                className="mb-0"
                                style={{ width: "250px" }}
                                name="cnpj"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, digite o CNPJ da sua empresa!",
                                    },
                                ]}
                            >
                                <InputComponent
                                    onChange={(e) => setCnpj(e.target.value)}
                                    placeholder={"CNPJ da sua empresa"}
                                    mask={"99.999.999/9999-99"}
                                    value={cnpj}
                                />
                            </Form.Item>
                            <span className="menu-label menu-label-red label-fasttime" style={{ padding: "8px 7px"}}>🔔 (É Rápido, leva menos de 1 minuto)</span>
                            <span className="text-center dados-protegidos-mob" style={{ color: "white", textAlign: "start !important"  }}>
                                🔒Dados protegidos | Lei nº. 13.709/2018 (LGPD)
                            </span>
                            <button
                                type="button"
                                style={{ height: "48px", marginLeft: "1%" }}
                                className="btn btn btn-success btn-form"
                                onClick={() => getDadosCnpj(cnpj)}
                                disabled={isLoading}
                            >
                                { isLoading && "Carregando..." }
                                { !isLoading && "INICIAR →" }
                            </button>
                        </form>
                        <span className="text-start dados-protegidos-desk d-md-inline-block w-100" style={{ color: "white", marginTop: "5px" }}>
                            🔒Dados protegidos | Lei nº. 13.709/2018 (LGPD)
                        </span>
                    </div>
                    <div
                        className="col-lg-6 text-center Eficiencia-tributaria d-flex flex-column justify-content-center"
                        style={{ marginBottom: "5% !important", marginTop: "3% !important" }}
                    >
                        {/*<img className="img-fluid" src="{{ config('app.url') . '/landingpage/general/img/logoEficienciaTributaria.jpg' }}" alt="logo Eficiencia Tributaria"/>*/}
                        <img
                            className="img-fluid"
                            src={process.env.PUBLIC_URL + "/fecomercio/logo-eficiencia-tributaria.jpg"}
                            alt="logo Eficiencia Tributaria"
                        />
                        <br />
                        <p className="" style={{ fontSize: "18px", marginTop: "10px", color: "white" }}>
                            Simples Nacional | Lucro Real | Lucro Presumido
                        </p>
                        {/*  <!-- Conteúdo da coluna direita -->  */}
                    </div>
                </div>
            </div>

            {/* <!-- section como funciona --> */}

            {/* <!-- Terceira Section --> */}
            <div className="container terceira-section pb-5" id="como-funciona">
                <div className="row">
                    <h4 className="text-center fw-bold" style={{ marginTop: "7%", marginBottom: "5% !important", color: "white" }}>
                        Como funciona para sua empresa!
                    </h4>

                    {/* <!-- Conteúdo da coluna esquerda--> */}
                    <div
                        className="col-md-4 text-center position-relative"
                        style={{ marginBottom: "5% !important", marginTop: "3% !important" }}
                    >
                        <img
                            className="img-fluid"
                            // src="{{ config('app.url') . '/landingpage/general/img/item1.png' }}"
                            src={process.env.PUBLIC_URL + "/fecomercio/item1.png"}
                            alt="logo Eficiencia Tributaria"
                            width="50%"
                            style={{ marginBottom: "5% !important" }}
                        />
                        <br />
                        <h5 style={{ color: "#C89633" }}>Passo 1</h5>
                        <p className="" style={{ fontSize: "18px", marginTop: "10px", color: "white" }}>
                            Cadastre sua empresa
                        </p>
                        <p style={{ color: "rgb(147, 147, 147)", fontSize: "14px", marginTop: "10px", padding: "0 15% 0 15%" }}>
                            Os seus dados são enviado para a nossa plataforma, dando inicio ao seu projeto de recuperação
                            tributária.
                        </p>
                        <img className="icone-flutuante" src="" alt="" />
                    </div>

                    {/* <!-- Conteúdo da coluna direita --> */}
                    <div
                        className="col-md-4 text-center position-relative"
                        style={{ marginBottom: "5% !important", marginTop: "3% !important" }}
                    >
                        {/*<img className="img-fluid" src="{{ config('app.url') . '/landingpage/general/img/item2.png' }}" alt="logo Eficiencia Tributaria" width="50%"*/}
                        <img
                            className="img-fluid"
                            src={process.env.PUBLIC_URL + "/fecomercio/item2.png"}
                            alt="logo Eficiencia Tributaria"
                            width="50%"
                            style={{ marginBottom: "5% !important" }}
                        />
                        <br />
                        <h5 style={{ color: "#C89633" }}>
                            Passo 2
                        </h5>
                        <p className="" style={{ fontSize: "18px", marginTop: "10px", color: "white" }}>
                            Responda o questionario de avaliação
                        </p>
                        <p style={{ color: "rgb(147, 147, 147)", fontSize: "14px", marginTop: "10px", padding: "0 15% 0 15%" }}>
                            Enviaremos um link em seu email e whatsapp para realização desta etapa.
                        </p>
                        <img className="icone-flutuante" src="" alt="" />
                    </div>

                    {/* <!-- Conteúdo da coluna direita --> */}
                    <div className="col-md-4 text-center" style={{ marginBottom: "5% !important", marginTop: "3% !important"  }}>
                        {/*<img className="img-fluid" src="{{ config('app.url') . '/landingpage/general/img/item3.png' }}" alt="logo Eficiencia Tributaria" width="50%"*/}
                        <img
                            className="img-fluid"
                            src={process.env.PUBLIC_URL + "/fecomercio/item3.png"}
                            alt="logo Eficiencia Tributaria"
                            width="50%"
                            style={{ marginBottom: "5% !important" }}
                        />
                        <br />
                        <h5 style={{ color: "#C89633" }}>Passo 3</h5>
                        <p className="" style={{ fontSize: "18px", marginTop: "10px", color: "white" }}>
                            Receba o resultado da sua análise técnica
                        </p>
                        <p style={{ color: "rgb(147, 147, 147)", fontSize: "14px", marginTop: "10px", padding: "0 15% 0 15%" }}>
                            Nosso sistema irá notifica-lo sobre a existência de possíveis valores a serem recuperados em até 24hrs.
                        </p>
                    </div>
                </div>
            </div>

            {/* <!-- quarta Section --> */}
            <div className="container quarta-section pb-5" id="clientes">
                <div className="row">
                    <h4 className="text-center" style={{ marginTop: "7%", marginBottom: "5% !important", color: "white" }}>
                        Empresas Participantes
                    </h4>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="carousel-wrapper">
                            <div id="clients-carousel" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner position-relative">
                                    <div className="carousel-item active" data-bs-interval="3000">
                                        <img src={process.env.PUBLIC_URL + "/fecomercio/companies/logo1.png"} className="d-block w-100" alt="Dimel Iluminação" />
                                    </div>
                                    <div className="carousel-item" data-bs-interval="3000">
                                        <img src={process.env.PUBLIC_URL + "/fecomercio/companies/logo2.png"} className="d-block w-100" alt="Bigolin Acabamentos" />
                                    </div>
                                    <div className="carousel-item" data-bs-interval="3000">
                                        <img src={process.env.PUBLIC_URL + "/fecomercio/companies/logo3.png"} className="d-block w-100" alt="EletroFios" />
                                    </div>
                                    <div className="carousel-item" data-bs-interval="3000">
                                        <img src={process.env.PUBLIC_URL + "/fecomercio/companies/logo4.png"} className="d-block w-100" alt="G4 Distribuidora" />
                                    </div>
                                    <div className="carousel-item" data-bs-interval="3000">
                                        <img src={process.env.PUBLIC_URL + "/fecomercio/companies/logo5.png"} className="d-block w-100" alt="Luz & Cia" />
                                    </div>
                                    <div className="carousel-item" data-bs-interval="3000">
                                        <img src={process.env.PUBLIC_URL + "/fecomercio/companies/logo6.png"} className="d-block w-100" alt="Marcenal" />
                                    </div>
                                    <div className="carousel-item" data-bs-interval="3000">
                                        <img src={process.env.PUBLIC_URL + "/fecomercio/companies/logo7.png"} className="d-block w-100" alt="Mercadão" />
                                    </div>
                                    <div className="carousel-item" data-bs-interval="3000">
                                        <img src={process.env.PUBLIC_URL + "/fecomercio/companies/logo8.png"} className="d-block w-100" alt="Moda Verão" />
                                    </div>
                                    <div className="carousel-item" data-bs-interval="3000">
                                        <img src={process.env.PUBLIC_URL + "/fecomercio/companies/logo9.png"} className="d-block w-100" alt="Só Pis" />
                                    </div>
                                    <div className="carousel-item" data-bs-interval="3000">
                                        <img src={process.env.PUBLIC_URL + "/fecomercio/companies/logo10.png"} className="d-block w-100" alt="Ypê Interiores" />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#clients-carousel" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#clients-carousel" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- quinta Section --> */}
            <div className="container quinta-section pb-5" id="beneficio">
                <div className="row">
                    <h4 className="text-center" style={{ marginTop: "7%", marginBottom: "5% !important", color: "white" }}>
                        O que sua empresa ganha com isso?
                    </h4>

                    {/* <!-- Conteúdo da coluna esquerda--> */}
                    <div className="col-md-4 text-center" style={{ marginBottom: "5% !important", marginTop: "3% !important" }}>
                        {/*<img className="img-fluid" src="{{ config('app.url') . '/landingpage/general/img/pratico_fecomercio.png' }}" alt="logo Eficiencia Tributaria" width="70%"*/}
                        <img
                            className="img-fluid"
                            src={process.env.PUBLIC_URL + "/fecomercio/pratico_fecomercio.png"}
                            alt="logo Eficiencia Tributaria"
                            width="70%"
                            style={{ marginBottom: "5% !important" }}
                        />
                        <br />
                        <h5 style={{ color: "#C89633" }}>Resultado Prático</h5>
                        <p className="" style={{ fontSize: "16px", marginTop: "10px", padding: "0 15% 0 15%", color: "white" }}>
                            Possibilidade de em até 90 dias (prazo médio) ter valores financeiro restituidos em conta corrente.
                        </p>
                    </div>

                    {/* <!-- Conteúdo da coluna direita --> */}
                    <div className="col-md-4 text-center" style={{ marginBottom: "5% !important", marginTop: "3% !important"  }}>
                        {/*<img className="img-fluid" src="{{ config('app.url') . '/landingpage/general/img/seguranca.png' }}" alt="logo Eficiencia Tributaria" width="70%"*/}
                        <img
                            className="img-fluid"
                            src={process.env.PUBLIC_URL + "/fecomercio/seguranca.png"}
                            alt="logo Eficiencia Tributaria"
                            width="70%"
                            style={{ marginBottom: "5% !important" }}
                        />
                        <br/>
                        <h5 style={{ color: "#C89633" }}>Segurança Jurídica</h5>
                        <p className="" style={{ fontSize:"16px", marginTop: "10px", padding: "0 15% 0 15%", color: "white" }}>
                            A combinação dos núcleos jurídicos, contábeis e os processos robotizados, revisam e levantam as melhores
                            oportunidades tributárias em favor de sua empresa.
                        </p>
                    </div>

                    {/* <!-- Conteúdo da coluna direita --> */}
                    <div className="col-md-4 text-center" style={{ marginBottom: "5% !important", marginTop: "3% !important" }}>
                        {/*<img className="img-fluid" src="{{ config('app.url') . '/landingpage/general/img/inovacao_fecomercio.png' }}" alt="logo Eficiencia Tributaria" width="70%"*/}
                        <img
                            className="img-fluid"
                            src={process.env.PUBLIC_URL + "/fecomercio/inovacao_fecomercio.png"}
                            alt="logo Eficiencia Tributaria"
                            width="70%"
                            style={{ marginBottom: "5% !important" }}
                        />
                        <br />
                        <h5 style={{ color: "#C89633" }}>Experiência | Inovação</h5>
                        <p className="" style={{ fontSize: "16px", marginTop: "10px", padding: "0 15% 0 15%", color: "white" }}>
                            Tecnologia fiscal e tributária disponíveis a todos os tipos de empresas, independente do porte e regime.
                        </p>
                    </div>
                </div>
            </div>

            {/* <!-- sexta Section --> */}
            <div className="container sexta-section pb-5" id="faq">
                <div className="row">
                    <br />
                    <br />
                    <h4 className="text-center" style={{ marginTop: "9%", marginBottom: "5%", color: "white" }}>
                        Perguntas Frequentes
                    </h4>
                    <br />
                    <br />
                    <div className="accordion w-100" id="basicAccordion">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#basicAccordionCollapseOne"
                                    aria-expanded="false"
                                    aria-controls="collapseOne"
                                >
                                    1- Qual o período que posso solicitar a restituição?
                                </button>
                            </h2>
                            <div
                                id="basicAccordionCollapseOne"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-mdb-parent="#basicAccordion"
                            >
                                <div className="accordion-body">
                                    Os últimos 60 meses.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#basicAccordionCollapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                >
                                    2- Minha empresa tem débitos, posso solicitar a restituição?
                                </button>
                            </h2>
                            <div
                                id="basicAccordionCollapseTwo"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-mdb-parent="#basicAccordion"
                            >
                                <div className="accordion-body">
                                    Sim. O valor recuperado será pela RFB compensado de ofício (crédito apurado (-)
                                    débito existente) sendo o saldo, se houver, creditado na conta bancária da empresa.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#basicAccordionCollapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                >
                                    3- Vou ser fiscalizado quando solicitar a restituição?
                                </button>
                            </h2>
                            <div
                                id="basicAccordionCollapseThree"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingThree"
                                data-mdb-parent="#basicAccordion"
                            >
                                <div className="accordion-body">
                                    O processo de recuperação tributária realizado pelas empresas não é fator determinante,
                                    tão pouco relevante para uma fiscalização da RFB. É importante destacarmos que estamos na
                                    era da alta tecnologia, sendo as empresas diariamente monitoradas pelos diversos órgãos de
                                    controle. Recuperar ou não valores financeiros de impostos não isenta ou protege o
                                    contribuinte brasileiro de uma possível ação fiscal.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#basicAccordionCollapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                >
                                    4- O dinheiro poderá ser depositado na conta corrente dos sócios?
                                </button>
                            </h2>
                            <div
                                id="basicAccordionCollapseFour"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingFour"
                                data-mdb-parent="#basicAccordion"
                            >
                                <div className="accordion-body">
                                    Não. Os valores financeiros recuperados de impostos são sempre creditados na conta bancária da
                                    empresa.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#basicAccordionCollapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseheadingFive"
                                >
                                    5- Qual a garantia tenho sobre o trabalho realizado?
                                </button>
                            </h2>
                            <div
                                id="basicAccordionCollapseFive"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingFive"
                                data-mdb-parent="#basicAccordion"
                            >
                                <div className="accordion-body">
                                    O direito a recuperação tributária na esfera administrativa é regulado pela Instrução
                                    Normativa RFB nº. 2055, de 06 de dezembro de 2021
                                    - http://normas.receita.fazenda.gov.br/sijut2consulta/link.action?visao=anotado&idAto=122002.
                                    Em esfera judicial o instrumento utilizado na maioria dos casos é o mandado de segurança
                                    que além de trazer significativa segurança jurídica não expõe a empresa a sucumbência.
                                    Independente da segurança jurídica acima destacada o escritório também conta com um seguro
                                    de responsabilidade civil profissional vigente, no valor de R$ 2 milhões de reais.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#basicAccordionCollapseSix"
                                    aria-expanded="false"
                                    aria-controls="collapseSix"
                                >
                                    6- A recuperação tributária contempla quais regimes fiscais?
                                </button>
                            </h2>
                            <div
                                id="basicAccordionCollapseSix"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingSix"
                                data-mdb-parent="#basicAccordion"
                            >
                                <div className="accordion-body">
                                    Todos os regimes vigentes na atualidade, ou seja, SIMPLES NACIONAL, LUCRO PRESUMIDO e LUCRO
                                    REAL.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeven">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#basicAccordionCollapseSeven"
                                    aria-expanded="false"
                                    aria-controls="collapseSeven"
                                >
                                    7- Tenho que tratar com meu contador antes?
                                </button>
                            </h2>
                            <div
                                id="basicAccordionCollapseSeven"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingSeven"
                                data-mdb-parent="#basicAccordion"
                            >
                                <div className="accordion-body">
                                    No primeiro momento, não. Ressaltamos que fazemos questão, no momento oportuno, do
                                    envolvimento do contabilista da empresa no projeto de recuperação tributária da sua empresa.
                                    Recomendamos de início a análise preliminar de viabilidade para em seguida conjuntamente
                                    com a diretoria da empresa e seus profissionais definirmos a sequência e implantação dos
                                    trabalhos.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingEight">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#basicAccordionCollapseEight"
                                    aria-expanded="false"
                                    aria-controls="collapseEight"
                                >
                                    8- Tenho parcelamento ativo na RFB. A recuperação tributária cabe para a minha empresa?
                                </button>
                            </h2>
                            <div
                                id="basicAccordionCollapseEight"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingEight"
                                data-mdb-parent="#basicAccordion"
                            >
                                <div className="accordion-body">
                                    Sim. Carecendo uma análise mais profunda do caso concreto sobre as possibilidades e
                                    desdobramentos, o que chamamos de viabilidade.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingNine">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#basicAccordionCollapseNine"
                                    aria-expanded="false"
                                    aria-controls="collapseNine"
                                >
                                    9- Se a Receita não me pagar ou não compensar meus impostos, terei que pagar algo para
                                    consultoria?
                                </button>
                            </h2>
                            <div
                                id="basicAccordionCollapseNine"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingNine"
                                data-mdb-parent="#basicAccordion"
                            >
                                <div className="accordion-body">
                                    NÃO. Trabalhamos no formato 100% colaborativo (êxito). Os honorários só serão devidos ao
                                    nosso escritório quando do efetivo benefício financeiro auferido pela empresa nas
                                    modalidades de restituição, ressarcimento ou ainda compensação.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTen">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#basicAccordionCollapseTen"
                                    aria-expanded="false"
                                    aria-controls="collapseTen"
                                >
                                    10- Qual o prazo médio para minha empresa começar a se beneficiar da recuperação tributária?
                                </button>
                            </h2>
                            <div
                                id="basicAccordionCollapseTen"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingTen"
                                data-mdb-parent="#basicAccordion"
                            >
                                <div className="accordion-body">
                                    Em média 90 dias.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
            </div>

            <div className="container pb-5">
                <div style={{ textAlign: 'center' }}>
                    <img
                        src={process.env.PUBLIC_URL + '/fecomercio/logo_fecomercio2.png'}
                        alt="Fecomercio MT"
                        width="250px"
                    />
                </div>
                <Footer style={{ marginTop: 0 }}>
                    <p style={{marginBottom: 0}}>Fecomércio-MT | CNPJ: 03.484.896/0001-10</p>
                    <p>Av. Historiador Rubens de Mendonça, 3501, CPA - 78049-090 - Cuiabá/MT</p>
                </Footer>
            </div>
        </div>
    )
}

export default FecomercioPage
