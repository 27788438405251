import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0 25px;
  box-sizing: border-box;
  align-items: center;
  background-color: #2c2a1f;
  font-family: 'inter', sans-serif;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  max-width: 800px;
  flex-direction: column;
  align-items: center;
  font-family: 'inter', sans-serif;
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ac8d4c;
  border-radius: 0 0 10px 10px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
  font-family: 'inter', sans-serif;
`;

export const BoxStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: white;
  gap: 10px;
  box-sizing: border-box;
`;

export const LucroPresumido = styled.div`
  display: flex;
  width: 100%;
  max-width: 200px;
  height: 30px;
  color: white;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  box-sizing: border-box;
  padding: 0 10px;
  box-shadow: 1 solid #ac8d4c;
  border-radius: 5px;
  font-family: 'inter', sans-serif;
  border: 1px solid #ffffff;
`;

export const Oportunidade = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  background-color: rgba(127, 105, 59, 0.2);
  color: white;
  margin-top: 25px;
  border-radius: 6px;
  padding: 30px;
  span {
    color: #ac8d4c;
    font-size: 18px;
    font-family: 'inter', sans-serif;
  }

  @media screen and (max-width: 555px) {
    width: 100%;
    padding: 20px;
    max-width: 90vw;
  }
`;

export const Federal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: "Inter", sans-serif;
  h2 {
    margin: 0 0 10px 0;
    font-size: 20px;
  }
  h3 {
    margin: 0;
    font-weight: 400;
  }

  @media screen and (max-width: 830px) {
    width: 100%;
    align-items: flex-start;
    h2 {
      text-align: start;
      justify-content: flex-start;
      font-size: 18px;
    }
    h3 {
      justify-content: flex-start;
      text-align: start;
      font-size: 18px;
    }
  }
`;

export const Estadual = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  font-family: "Inter", sans-serif;
  h2 {
    margin: 0 0 10px 0;
    font-size: 20px;
  }
  h3 {
    margin: 0;
    font-weight: 400;
  }

  @media screen and (max-width: 830px) {
    width: 100%;
    align-items: flex-start;
    h2 {
      text-align: start;
      justify-content: flex-start;
      font-size: 22px;
    }
    h3 {
      justify-content: flex-start;
      text-align: start;
      font-size: 20px;
    }
  }
`;

export const ADMINITRATIVAS = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 0 0 10px;
  box-sizing: border-box;
  @media screen and (max-width: 830px) {
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    font-size: 14px;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 476px) {
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    font-size: 14px;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
`;

export const JUDICIAIS = styled.div`
  display: flex;
  font-family: 'inter', sans-serif;
  width: 100%;
  margin: 20px 0 0 10px;
  box-sizing: border-box;
  @media screen and (max-width: 830px) {
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    font-size: 14px;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 476px) {
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    font-size: 14px;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
`;

export const Total = styled.div`
  display: none;
  // display: flex;
  background-color: #3c3725;
  z-index: 999;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 100px;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  margin-left: -240px;
  margin-top: 110px;
  font-size: 40px;
  border-radius: 10%;
  color: white;
  box-sizing: border-box;
  padding: 10px;
  @media screen and (max-width: 830px) {
    width: 25%;
    // margin-left: -560px;
    margin-left: -32VW;
    margin-right: 200px;
    margin-top: 0px;
    z-index: 999;
  }
  @media screen and (max-width: 476px) {
    width: 30%;
    margin-left: -32VW;
    // margin-left: 80px;
    margin-top: -360px;
    margin-right: 120px;
    z-index: 999;
  }
`;

export const Graf = styled.div`
  display: flex;
  // width: 300px;
  height: 100%;
  margin-right: 30px;
  box-sizing: border-box;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 476px) {
    margin-left: 100px;
  }
`;

export const ContainerMapa = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const BoxDonutChart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const BoxMapa = styled.div`
  width: 50%;
  >div{
    width: 100%;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;
