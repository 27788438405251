import styled from 'styled-components'

export const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  height: 50px;
  padding: 30px;
  border-radius: 5px;
  font-size: 18px;
  font-family: 'inter', sans-serif;
`

export default Input