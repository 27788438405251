import React, { useState, useEffect } from "react";
import { Container } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {pathPrefix} from "../../../data/pathPrefix";
import useQuery from "../../../hooks/useQuery";

const LoadingFinal = () => {
    const location = useLocation();
    const { body } = location?.state || {};
    const navigate = useNavigate();

    const time = 1660;
    const isRequesting = React.useRef(false)
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [requestFinish, setRequestFinish] = useState(false);
    const [textFinish, setTextFinish] = useState(false);
    const [loadingValue, setLoadingValue] = useState(0);

    const loadingTexts = [
        "Salvando informações no sistema",
        "Iniciando o cruzamento de dados...",
        "+ 30 milhões de produtos classificados",
        "+ 100 milhões de regras tributárias distintas",
        "+ 190 bilhões de combinações de regras fiscais",
    ];

    const queryParams = useQuery()

    let interval = null;

    const executeLoading = () => {
        interval = setInterval(() => {
            setCurrentTextIndex((prevIndex) => prevIndex + 1);
        }, time);
    };

    const getDados = async () => {
        axios.post(
            `${process.env.REACT_APP_API_URL}/api/external/project/questionnaire?${queryParams.toString()}`,
            body
        )
        .then((response) => {
            const prefix = pathPrefix()

            navigate({
                pathname: prefix + "/conclusao",
                search: queryParams.toString()
            });
            setRequestFinish(true)
        })
        .catch((error) => {
            console.error(error);
        });
    };

    useEffect(() => {
        if (!isRequesting.current) {
            getDados();
            isRequesting.current = true
        }

        executeLoading();
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingTexts.length]);

    useEffect(() => {
        if (currentTextIndex === loadingTexts.length) {
            setTextFinish(true);
        }

        let timeTeste = null;
        setLoadingValue(0);
        timeTeste = setInterval(() => {
            setLoadingValue((prevIndex) => {
                if (prevIndex === 100) return (prevIndex = 0);
                return (prevIndex = prevIndex + 100 / loadingTexts.length);
            });
        }, time / (loadingTexts.length + 1));

        if (currentTextIndex >= loadingTexts.length && requestFinish) {
            clearInterval(interval);

            navigate(
                {
                    pathname: "/conclusao",
                    search: queryParams.toString()
                }
            );
        }
        return () => clearInterval(timeTeste);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTextIndex]);

    return (
        <Container loadingValue={loadingValue} tamanhoLista={loadingTexts.length} textFinish={textFinish}>
            <div className="custom-loader"></div>
            <div className="loading-text">{loadingTexts[currentTextIndex >= loadingTexts.length ? loadingTexts.length - 1 : currentTextIndex]}</div>
        </Container>
    );
};

export default LoadingFinal;
