import { TextIcon } from "./style";


export default function TextIconComponent({ text, icon, style }) {
    return (
        <TextIcon
            style={style}
        >
            <span>{icon}</span>
            <p>{text}</p>
        </TextIcon>
    );
}