import { useEffect, useState } from "react";
import { AiOutlineInfoCircle, AiOutlineShop } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { LuMapPin } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonComponent from "../../common/component/Button";
import CardComponent from "../../common/component/Card";
import InputComponent from "../../common/component/Input";
import TextIconComponent from "../../common/component/TextIcon";
import { Box, BoxStyle, Card, Container } from "./style";
import { Form, Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectComponent from "../../common/component/Select";
import {pathPrefix} from "../../data/pathPrefix";
import useQuery from "../../hooks/useQuery";

export default function Step02() {
    const location = useLocation();
    const { dados, teveErro = false } = location?.state || {};
    const navigate = useNavigate();
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [whatsaap, setWhatsaap] = useState("");
    const [regimeTributario, setRegimeTributario] = useState(
        dados.simples.optante ? "3" : "0"
    );
    const [loading, setLoading] = useState(false);

    const queryParams = useQuery()

    const tributeTypes = {
        "1": "lucro_presumido",
        "2": "lucro_real",
        "3": "simples_nacional",
    };

    const formatCnpj = (cnpj) => {
        return `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(5,8)}/${cnpj.substring(8, 12)}-${cnpj.substring(12, 14)}`;
    };

    const data = {
        cnpj: dados?.cnpj,
        corporate_name: dados?.razao_social,
        tribute_type: tributeTypes[regimeTributario] || "Lucro Real",
        representative_name: nome,
        representative_email: email,
        representative_cpf: "00000000000",
        representative_phone: whatsaap,
    };

    const onFinish = () => {
        const prefix = pathPrefix()
        setLoading(true);

        navigate(
            {
                pathname: prefix + '/loading',
                search: queryParams.toString()
            },
            {
                state: {
                    data,
                    dados,
                    regimeTributario
                },
            }
        );
    };

    useEffect(() => {
        setLoading(false);
        if (teveErro) {
            toast.error("Erro ao cadastrar, tente novamente!");
        }
    }, [teveErro]);


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Form
                onFinish={(e) => {
                    onFinish(e);
                    setLoading(true);
                }}
            >
                <Container>
                    <Box>
                        <Card>Análise preliminar</Card>
                        <Spin tip="Loading" size="small"
                            spinning={loading}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                zIndex: "9999",
                                backgroundColor: "rgba(255, 255, 255, 0.5)",
                            }}
                        >
                            <div className="content" />
                        </Spin>

                        <BoxStyle>
                            <CardComponent texto={"Informações da empresa"} />

                            <TextIconComponent
                                style={{ justifyContent: "flex-start" }}
                                text={`${dados?.razao_social || "Não encontrado"} `}
                                icon={<AiOutlineShop />}
                            />
                            <TextIconComponent
                                style={{ justifyContent: "flex-start" }}
                                text={`${dados?.cnpj ? formatCnpj(dados?.cnpj) : "Não encontrado"
                                    }`}
                                icon={<AiOutlineInfoCircle />}
                            />
                            <TextIconComponent
                                style={{ justifyContent: "flex-start" }}
                                text={`${dados?.endereco.estado.nome || "Não encontrado"} `}
                                icon={<LuMapPin />}
                            />

                            <CardComponent texto={"Qual seu regime tributário?"} />

                            <Form.Item
                                name="regimeTributario"
                                rules={[
                                    {
                                        required: regimeTributario === "0" ? true : false,
                                        message: "Por favor, selecione o regime tributário!",
                                    },
                                ]}

                            >
                                <SelectComponent
                                    onChange={(e) => setRegimeTributario(e.target.value)}
                                    placeholder={"Selecione"}
                                    value={regimeTributario}
                                    defaultValue={
                                        dados.simples.optante ? "3" : "0"
                                    }
                                    options={[
                                        { value: "0", label: "Selecione o regime tributário" },
                                        { value: "3", label: "Simples Nacional" },
                                        { value: "1", label: "Lucro presumido" },
                                        { value: "2", label: "Lucro real" },
                                    ]}
                                    style={{
                                        backgroundColor: "#2c2a1f",
                                        color: "white",
                                    }}
                                />
                            </Form.Item>

                            <CardComponent texto={"Representante Legal"} />

                            <h3>
                                NOME <b>*</b>
                            </h3>
                            <Form.Item
                                name="nome"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, digite o nome do representante legal!",
                                    },
                                ]}
                            >
                                <InputComponent
                                    onChange={(e) => setNome(e.target.value)}
                                    placeholder={"Nome do Representante Legal"}
                                />
                            </Form.Item>
                            <h3>
                                E-MAIL <b>*</b>
                            </h3>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, digite o e-mail do representante legal!",
                                    },
                                ]}
                            >
                                <InputComponent
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder={"E-mail do Representante Legal"}
                                />
                            </Form.Item>
                            <h3>
                                WHATSAAP <b>*</b>
                            </h3>
                            <Form.Item
                                name="whatsaap"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Por favor, digite o whatsapp do representante legal!",
                                    },
                                ]}
                            >
                                <InputComponent
                                    onChange={(e) => setWhatsaap(e.target.value)}
                                    mask="(99) 99999-9999"
                                    placeholder="(99) 99999-9999"
                                />
                            </Form.Item>
                            <ButtonComponent
                                type="submit"
                                text={loading ? "Carregando..." : "Cadastrar"}
                                icon={<BsArrowRight />}
                            />
                        </BoxStyle>
                    </Box>
                </Container>
            </Form>
        </>

    );
}
