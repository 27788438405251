import { geoCentroid } from "d3-geo";
import React from "react";
import {
    Annotation,
    ComposableMap,
    Geographies,
    Geography,
    Marker,
} from "react-simple-maps";

import brTopoJson from "./data/br-topo.json";
// import statesWithAnnotations from "./data/estados";
const statesWithAnnotations = {
    BR_DF: {
        annotation: { x: -10, y: -15 },
        tag: { fontSize: 14, x: 4, y: 0 },
    },
    BR_RN: {
        annotation: { x: 28, y: 0 },
        tag: { fontSize: 14, x: 4, y: 0 },
    },
    BR_PB: {
        annotation: { x: 32, y: 0 },
        tag: { fontSize: 14, x: 4, y: 0 },
    },
    BR_PE: {
        annotation: { x: 50, y: 0 },
        tag: { fontSize: 14, x: 4, y: 0 },
    },
    BR_AL: {
        annotation: { x: 30, y: 0 },
        tag: { fontSize: 14, x: 4, y: 0 },
    },
    BR_SE: {
        annotation: { x: 25, y: 0 },
        tag: { fontSize: 14, x: 4, y: 0 },
    },
    BR_ES: {
        annotation: { x: 20, y: 0 },
        tag: { fontSize: 14, x: 4, y: 0 },
    },
    BR_RJ: {
        annotation: { x: 25, y: 0 },
        tag: { fontSize: 14, x: 4, y: 0 },
    },
    PY_AM: {
        annotation: { x: 16, y: -10 },
        tag: { fontSize: 14, x: 2, y: 0 },
    },
    PY_AA: {
        annotation: { x: 15, y: 0 },
        tag: { fontSize: 14, x: 4, y: 0 },
    },
    PY_GU: {
        annotation: { x: -90, y: 75 },
        tag: { fontSize: 14, x: 2, y: 8 },
    },
    PY_PG: {
        annotation: { x: -130, y: 30 },
        tag: { fontSize: 14, x: 4, y: 10 },
    },
    PY_CZ: {
        annotation: { x: -40, y: 100 },
        tag: { fontSize: 14, x: 0, y: 8 },
    },
    PY_NE: {
        annotation: { x: -100, y: 45 },
        tag: { fontSize: 14, x: 4, y: 8 },
    },
    PY_CE: {
        annotation: { x: -110, y: 10 },
        tag: { fontSize: 14, x: 4, y: 10 },
    },
    PY_CR: {
        annotation: { x: -100, y: -30 },
        tag: { fontSize: 14, x: -4, y: 12 },
    },
    PY_AS: {
        annotation: { x: -110, y: -10 },
        tag: { fontSize: 14, x: 4, y: 10 },
    },
    PY_MI: {
        annotation: { x: -25, y: 30 },
        tag: { fontSize: 14, x: -5, y: 10 },
    },
};

const geographyStyle = {
    fill: "#ECEFF1",
    backgroundColor: "yellow",
    stroke: "white",
    strokeWidth: 1,
    outline: "none",
    cursor: "pointer",
    transition: "all .2s",
};

const MapChart = ({ sigla }) => {
    const renderGeograph = (dataSource, countryId, countryColor) => {
        return (
            <Geographies geography={dataSource}>
                {({ geographies }) => (
                    <>
                        {geographies.map((geo) => {
                            return (
                                <Geography
                                    key={geo.rsmKey + "-Geography"}
                                    stroke="#FFF"
                                    geography={geo}
                                    style={{
                                        default: {
                                            ...geographyStyle,
                                            fill: `${geo.properties.id === sigla ? "#DFBF7D" : countryColor}`,
                                        },
                                        hover: {
                                            ...geographyStyle,
                                            fill: "#DFBF7D"
                                        },
                                        pressed: {
                                            ...geographyStyle,
                                            fill: "black",
                                        },
                                    }}
                                />
                            );
                        })}

                        {geographies.map((geo) => {
                            const centroid = geoCentroid(geo);
                            const geoId = geo.properties.id;
                            const stateId = `${countryId}_${geoId}`.replace("MT", "teste");
                            // const annotationOffset =
                            //     statesWithAnnotations[`${countryId}_${geoId}`];
                            const annotationOffset = statesWithAnnotations[stateId];
                            const tagPosition = annotationOffset?.tag || {
                                x: 2,
                                y: 0,
                                fontSize: 12,
                            };
                            return (
                                <g
                                    key={`${geo.rsmKey}-Marker`}
                                    style={{ pointerEvents: "none" }}
                                >
                                    {annotationOffset ? (
                                        <Annotation
                                            connectorProps={{
                                                stroke: "rgb(239,171,101)",
                                            }}
                                            subject={centroid}
                                            dx={annotationOffset.annotation.x}
                                            dy={annotationOffset.annotation.y}
                                        >
                                            <text
                                                x={geoId === "DF" ? tagPosition.x - 15 : tagPosition.x}
                                                y={geoId === "DF" ? tagPosition.y - 10 : tagPosition.y}
                                                fontSize={tagPosition.fontSize}
                                                alignmentBaseline="middle"
                                                style={{ fontWeight: `${geoId === sigla ? "bold" : "normal"}`, fill: "#fff" }}
                                            >
                                                {`${sigla === geoId ? "1" : geoId}`}
                                            </text>
                                        </Annotation>
                                    ) : (
                                        <Marker coordinates={centroid}>
                                            <text
                                                x={sigla === geoId ? tagPosition.x + 2 : tagPosition.x}
                                                y={sigla === geoId ? tagPosition.y + 6 : tagPosition.y}
                                                fontSize={sigla === geoId ? 20 : tagPosition.fontSize}
                                                style={{ fontWeight: `${geoId === sigla ? "bold" : "normal"}`, fill: "#fff" }}
                                                textAnchor="middle"
                                            >
                                                {`${sigla === geoId ? `1` : geoId}`}
                                            </text>
                                        </Marker>
                                    )}
                                </g>
                            );
                        })}
                    </>
                )
                }
            </Geographies >
        );
    };

    return (
        <div>
            <ComposableMap
                projection="geoMercator"
                projectionConfig={{
                    scale: 800,
                    center: [-54, -15],
                }}
                width={600}
                height={600}
            >
                {renderGeograph(brTopoJson, "BR", "#AC8D4C")}
            </ComposableMap>
        </div>
    );
};

export default MapChart;
