export const simulateTributeRecoverCalculation = (amount, percentageCompany) => {
    // var valorMensal = $("#monthlyValue").val();
    let valorMensal = amount
    let valorAnual = 0;
    // var porcentagemCategoria = parseFloat($("#typeEstablishment").val()).toFixed(4);
    let porcentagemCategoria = parseFloat(percentageCompany).toFixed(4);
    let AliquotaFaixa = [];
    let pisFaixa = [];
    let valorDeduzirFaixa = [];
    let cofinsFaixa = [];
    let aliquotaEfetiva;
    let calculo2;
    let tributado;
    let monofasico;
    let monofasicoFinal;
    let totalRecalculado;
    let restituido;
    let aux;
    let i;

    valorAnual = valorMensal * 12.0

    // valorMensal = valorMensal.replace('.', '');
    // valorMensal = parseFloat(valorMensal.replace(',', '.'));
    // valorAnual = parseFloat(valorMensal * parseFloat(12));

    if (valorAnual <= 180000) {
        i = 0;
    } else if (valorAnual > 180000 && valorAnual <= 360000) {
        i = 1;
    } else if (valorAnual > 360000 && valorAnual <= 720000) {
        i = 2;
    } else if (valorAnual > 720000 && valorAnual <= 1800000) {
        i = 3;
    } else if (valorAnual > 1800000 && valorAnual <= 3600000) {
        i = 4;
    } else if (valorAnual > 3600000 && valorAnual < 4800000) {
        i = 5;
    } else {
        i = 6;
    } //Aliquota em %

    AliquotaFaixa[0] = 0.04;
    AliquotaFaixa[1] = 0.073;
    AliquotaFaixa[2] = 0.095;
    AliquotaFaixa[3] = 0.107;
    AliquotaFaixa[4] = 0.143;
    AliquotaFaixa[5] = 0.19; //PIS/PASEP em %

    if (i !== 6) {
        pisFaixa[i] = 0.0276;
    } else {
        pisFaixa[i] = 0.0613;
    } // Cofins em %


    if (i !== 6) {
        cofinsFaixa[i] = 0.1274;
    } else {
        cofinsFaixa[i] = 0.2827;
    } //valor a deduzir em R$


    valorDeduzirFaixa[0] = 0;
    valorDeduzirFaixa[1] = 5940;
    valorDeduzirFaixa[2] = 13860;
    valorDeduzirFaixa[3] = 22500;
    valorDeduzirFaixa[4] = 87300;
    valorDeduzirFaixa[5] = 378000; //ALÍQUOTA EFETIVA

    aliquotaEfetiva = (valorAnual * AliquotaFaixa[i] - valorDeduzirFaixa[i]) / valorAnual;
    aliquotaEfetiva = parseFloat(aliquotaEfetiva.toFixed(6)); //CÁLCULO DO SIMPLES NACIONAL

    calculo2 = valorMensal * aliquotaEfetiva; //Seguimento - 100%

    aux = 1 - porcentagemCategoria;
    aux = parseFloat(aux).toFixed(2); //BASE DE aliquotaEfetiva (seguimento) TRIBUTADO

    tributado = valorMensal * aux * aliquotaEfetiva; //Porcentagem do MONOFASICO

    var somaGerais = cofinsFaixa[i] + pisFaixa[i];
    var multiGerais = aliquotaEfetiva * somaGerais;
    monofasico = aliquotaEfetiva - multiGerais;
    monofasico = parseFloat(monofasico).toFixed(4); //BASE DE aliquotaEfetiva 80% MONOFASICO

    monofasicoFinal = valorMensal * porcentagemCategoria * monofasico; //TOTAL RECALCULADO

    totalRecalculado = tributado + monofasicoFinal; //ECONOMIA MENSAL

    let economiaMensal = calculo2 - totalRecalculado; //VALOR RESTITUIDO
    restituido = economiaMensal * 60;

    if (i !== 6) {
        return {
            restituido: Number(restituido).toFixed(2),
            economiaMensal: Number(economiaMensal).toFixed(2)
        }
    } else {
        return {
            restituido: false,
            economiaMensal: false
        }
    }
}
