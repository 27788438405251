import { BsArrowRight } from "react-icons/bs";
import CardComponent from "../../common/component/Card";
import { Box, BoxStyle, Container, Card, Button } from "./styled";
import ButtonComponent from "../../common/component/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Etapas from "../../common/component/Steps";
import { useState } from "react";
import { Progress } from "antd";
import {pathPrefix} from "../../data/pathPrefix";
import useQuery from "../../hooks/useQuery";

export default function Step08() {
    const navigate = useNavigate();
    const [activeSim, setActiveSim] = useState(false);
    const [activeNao, setActiveNao] = useState(false);
    const [comentarioFiscal, setComentario] = useState("");
    const location = useLocation();
    const { project_id, questionario, faturamento, valor12meses } = location?.state || {};

    const queryParams = useQuery()

    const handleYesClick = () => {
        setActiveSim(true);
        setActiveNao(false);
    };
    const handleNoClick = () => {
        setActiveSim(false);
        setActiveNao(true);
    };

    return (

        <Container>
            <Box>
                <Card>Questionário Preliminar</Card>

                <BoxStyle>
                    <Etapas />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            margin: " 0",
                        }}
                    >
                        <Progress
                            percent={50}
                            showInfo={false}
                            strokeColor={{
                                "0%": "#50c863",
                                "100%": "#50c863",
                            }}
                            trailColor={"#e5e5e5"}
                            style={{ width: "100%", marginBottom: "1rem", height: "0px" }}
                        />
                        <p>Progresso</p>
                    </div>
                    <CardComponent
                        texto={"A empresa possui algum benefício fiscal estadual?"}
                    />

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            gap: "1rem",
                        }}
                    >

                        <Button
                            active={activeSim}
                            onClick={() => {
                                handleYesClick();
                            }}
                        >
                            Sim
                        </Button>
                        <Button
                            active={activeNao}
                            onClick={() => {
                                handleNoClick();
                            }}
                        >
                            Não
                        </Button>

                    </div>

                    {activeSim && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                gap: "1rem",
                                marginTop: "1rem",
                            }}
                        >
                            SE POSSÍVEL COMENTE:
                            <textarea
                                style={{
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: "6px",
                                    border: "none",
                                    padding: "10px",
                                    boxSizing: "border-box",
                                    backgroundColor: "white",
                                    fontSize: "18px",
                                }}
                                placeholder={"Digite seu comentário..."}
                                onChange={(e) => setComentario(e.target.value)}
                                value={comentarioFiscal}
                            />
                        </div>
                    )}

                    <ButtonComponent
                        onClick={() => {
                            const prefix = pathPrefix()

                            navigate(
                                {
                                    pathname: prefix + "/faturamento/anual",
                                    search: queryParams.toString()
                                },
                                {
                                    state: {
                                        project_id,
                                        questionario,
                                        faturamento,
                                        valor12meses,
                                        FiscalEstadual: {
                                            activeSim,
                                            activeNao,
                                            comentarioFiscal
                                        }
                                    },
                                }
                            )
                        }}
                        text={"PRÓXIMO"}
                        icon={<BsArrowRight />}
                    />
                </BoxStyle>
            </Box>
        </Container>

    );
}
