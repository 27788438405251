import * as React from 'react'
import InAppRotas from "./routes/in_app_routes";
import IndependentRotas from "./routes/independent";
import {pathPrefix} from "./data/pathPrefix";

export const App = () => {
    const prefix = pathPrefix()

    return (
        <>
            {process.env.REACT_APP_IS_INSIDE_WEBAPP && <InAppRotas prefix={prefix} />}
            {!process.env.REACT_APP_IS_INSIDE_WEBAPP && <IndependentRotas prefix={prefix} />}
        </>
    )
}

export default App
