import React, { useState, useEffect } from "react";
import { Container } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {pathPrefix} from "../../../data/pathPrefix";
import useQuery from "../../../hooks/useQuery";

const LoadingComponent = () => {
    const location = useLocation();
    const { dados, regimeTributario, data } = location?.state || {};
    const navigate = useNavigate();

    const time = 1660;
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [requestFinish, setRequestFinish] = useState(false);
    const [textFinish, setTextFinish] = useState(false);
    const [loadingValue, setLoadingValue] = useState(0);
    const [projectId, setProjectId] = useState(null);
    const loadingTexts = [
        "Por favor aguarde. Carregando informações recebidas da sua empresa...",
        "Processando os dados...",
        "Exibindo resultados preliminares do seu Projeto Eficiência Tributária...",
    ];

    const queryParams = useQuery()

    let interval = null;

    const isRequesting = React.useRef(false)

    const executeLoading = () => {
        interval = setInterval(() => {
            setCurrentTextIndex((prevIndex) => prevIndex + 1);
        }, time);
    };

    const getDados = async () => {
        const _data = {
            company_name: process.env.REACT_APP_COMPANY_NAME || 'ffadigital',
            ...data
        }

        axios.post(
            `${process.env.REACT_APP_API_URL}/api/external/project/register?${queryParams.toString()}`,
            _data,
            {
                headers: {
                    "Accept ": "application/json",
                    "Content-Type": "application/json",
                },
            }
        )
        .then((resposta) => {
            setProjectId(resposta.data.project_id)
            setRequestFinish(true)
        })
        .catch((error) => {
            const prefix = pathPrefix()

            navigate(
                {
                    pathname: prefix + '/informacoes',
                    search: queryParams.toString()
                },
                {
                    state: {
                        data,
                        dados,
                        regimeTributario,
                        teveErro: true
                    },
                }
            );
            Response.errors = error;
            console.error(error);
        });
    };

    useEffect(() => {
        if (!isRequesting.current) {
            getDados();
            isRequesting.current = true
        }

        executeLoading();
        return () => clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingTexts.length]);

    useEffect(() => {
        if (currentTextIndex === loadingTexts.length) {
            setTextFinish(true);
        }

        let timeTeste = null;
        setLoadingValue(0);
        timeTeste = setInterval(() => {
            setLoadingValue((prevIndex) => {
                if (prevIndex === 100) return (prevIndex = 0);
                return (prevIndex = prevIndex + 100 / loadingTexts.length);
            });
        }, time / (loadingTexts.length + 1));

        if (currentTextIndex >= loadingTexts.length && requestFinish) {
            const prefix = pathPrefix()

            clearInterval(interval);

            navigate(
                {
                    pathname: prefix + "/informacoes/dados",
                    search: queryParams.toString()
                },
                {
                    state: {
                        dados,
                        regimeTributario,
                        project_id: projectId,
                    },
                }
            );
        }
        return () => clearInterval(timeTeste);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTextIndex]);

    return (
        <Container loadingValue={loadingValue} tamanhoLista={loadingTexts.length} textFinish={textFinish}>
            {/* <div className="loading-spinner" /> */}
            <div className="custom-loader"></div>
            <div className="loading-text">{loadingTexts[currentTextIndex >= loadingTexts.length ? loadingTexts.length - 1 : currentTextIndex]}</div>
        </Container>
    );
};

export default LoadingComponent;
