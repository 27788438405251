import { Button } from "./style";

export default function ButtonComponent({ onClick, text, icon, disabled, type,className }) {
    return (
        <Button type={type || 'button'} onClick={onClick} disabled={disabled} className={className} style={{ display: className === "item3" ? "none" : "inherit" }}>
            {text || ""}
            {icon || ""}
        </Button>
    );
}
