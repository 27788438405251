import styled from "styled-components";

export const Select = styled.select`
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ffffff;
  padding: 10px;
  font-family: 'inter', sans-serif;
  &:focus {
    outline: none;
  }
  /* Estiliza a seta para a esquerda */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;
