import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import EtapaInicial from "../page/Step-01";
import Step02 from "../page/Step-02";
import Step03 from "../page/Step-03";
import Step04 from "../page/Step-04";
import Step05 from "../page/Step-05";
import Step06 from "../page/Step-06";
import Step07 from "../page/Step-07";
import Step08 from "../page/Step-08";
import Step09 from "../page/Step-09";
import Step10 from "../page/Step-10";

import LoadingComponent from "../common/component/LoadingInicial";
import Step13 from "../page/Step-13";
import Step14 from "../page/Step-14";
import Step12 from "../page/Step-12";
import Step11 from "../page/Step-11";
import LoadingFinal from "../common/component/LoadingFinal";
import Step04Calculadora from "../page/Step-04_1-Calculadora";
import Step04CalculadoraResultado from "../page/Step-04_2-Calculadora";
import Step10_1 from "../page/Step-10_1";

export default function Rotas({ prefix }) {
    return (
        <BrowserRouter>
            <Routes onUpdate={() => window.scrollTo(0, 0)}>
                <Route path={prefix + "/"} element={<EtapaInicial />} />
                <Route path={prefix + "/informacoes"} element={<Step02 />} />
                <Route path={prefix + "/informacoes/dados"} element={<Step03 />} />
                <Route path={prefix + "/iniciar/calculo"} element={<Step04Calculadora />} />
                <Route path={prefix + "/iniciar/calculo/resultado"} element={<Step04CalculadoraResultado />} />
                <Route path={prefix + "/iniciar/recuperacao"} element={<Step04 />} />
                <Route path={prefix + "/informe/tributario"} element={<Step05 />} />
                <Route path={prefix + "/informe/tributario-bruto"} element={<Step06 />} />
                <Route path={prefix + "/informe/valor"} element={<Step07 />} />
                <Route path={prefix + "/beneficio/fiscal"} element={<Step08 />} />
                <Route path={prefix + "/faturamento/anual"} element={<Step09 />} />
                <Route path={prefix + "/recuperacao/tributaria"} element={<Step10 />} />
                <Route path={prefix + "/recuperacao/tributariaa"} element={<Step10_1 />} />
                <Route path={prefix + "/planejamento"} element={<Step11 />} />
                <Route path={prefix + "/auditoria"} element={<Step12 />} />
                <Route path={prefix + "/termo/confidencialidade"} element={<Step13 />} />
                <Route path={prefix + "/conclusao"} element={<Step14 />} />
                <Route path={prefix + "/loading"} element={<LoadingComponent />} />
                <Route path={prefix + "/salvando"} element={<LoadingFinal />} />
            </Routes>
        </BrowserRouter>
    );
}
