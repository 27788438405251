import * as React from 'react'
import InputMask from "inputmask";
import {Input} from "./style";

export const CurrencyInput = ({ defaultValue, onChange }) => {
    const inputRef = React.useRef()

    React.useEffect(() => {
        InputMask({
            prefix: "R$ ",
            radixPoint: ',',
            groupSeparator: ".",
            alias: "numeric",
            placeholder: "0",
            digits: 2,
            digitsOptional: false,
            clearMaskOnLostFocus: false,
            numericInput: true,
            oncomplete: () => {
                const value = inputRef.current?.value.replace(/[^\d,]/g, '').replace(',', '.')
                onChange(Number(value))
            },
            oncleared: () => {
                onChange(Number(0))
            },
            rightAlign: false,
        }).mask(inputRef.current)
    }, [inputRef,onChange])

    return <Input ref={inputRef} defaultValue={defaultValue} />
}

CurrencyInput.defaultProps = {
    defaultValue: 0
}

export default CurrencyInput