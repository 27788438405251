import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0 25px;
  box-sizing: border-box;
  align-items: center;
  background-color: #2c2a1f;
  font-family: 'inter', sans-serif;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  max-width: 800px;
  flex-direction: column;
  align-items: center;

`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ac8d4c;
  border-radius: 0 0 10px 10px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
`;

export const BoxStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: white;
  gap: 10px;
  box-sizing: border-box;
`;

export const Button = styled.button`
  display: flex;
  width: 50%;
  height: 70px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  color: black;
  font-size: 25px;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  margin-top: 20px;
  cursor: pointer;
  ${(props) => props.active && `
    border: 4px solid #AC8D4C;
    background-color: #DFBF7E;
    color: white;
  `}
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: green;
  }
`;

