import {
    Box,
    BoxStyle,
    Container,
    Card,
    CardCenter,
    CardImportante,
    Contratuais,
    BoxContratuais,
    Row,
    Cartao,
    Contato,
    Whats,
} from "./styled";
import TextIconComponent from "../../common/component/TextIcon";
import { ReactComponent as Whatsapp } from "../../common/component/svg/Whatsapp.svg";

import { ReactComponent as Trofeu } from "../../common/component/svg/Trofeu.svg";
import { ReactComponent as Contrato } from "../../common/component/svg/Contrato.svg";
import { ReactComponent as Coleta } from "../../common/component/svg/Coleta.svg";
import { ReactComponent as Levantamento } from "../../common/component/svg/Levantamento.svg";
import { ReactComponent as Apresentacao } from "../../common/component/svg/Apresentacao.svg";
import { ReactComponent as Implantacao } from "../../common/component/svg/Implantacao.svg";
import { ReactComponent as Deferimento } from "../../common/component/svg/Deferindo.svg";
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";

export default function Step14() {
    return (
        <Container>
            <Box>
                <Card>Questionário Preliminar</Card>
                <CardCenter>
                    <CardImportante>
                        <Trofeu />
                        <h3>Parabéns</h3>
                        <h4>Informações recebidas! </h4>
                        <Whats>
                            <BsWhatsapp />
                            Em até 24hs você será notificado
                            <AiOutlineMail />
                        </Whats>
                        <TextIconComponent
                            style={{ margin: "50px 0 50px 0", textAlign: "Justify" }}
                            text={
                                "sobre a conclusão da análise preliminar de viabilidade do Projeto Eficiência Tributária da sua empresa."
                            }
                        />
                    </CardImportante>
                </CardCenter>

                <BoxStyle>
                    <Cartao>
                        <h2>Próximas Etapas | Contratuais</h2>
                    </Cartao>
                    <Row>
                        <BoxContratuais>
                            <Contratuais>
                                <Contrato
                                    style={{ width: "50%", height: "100%", margin: "0 auto" }}
                                />
                            </Contratuais>
                            <p>Contrato de Serviços</p>
                        </BoxContratuais>

                        <BoxContratuais>
                            <Contratuais>
                                <Coleta style={{ width: "50%", height: "100%", margin: "0 auto" }} />
                            </Contratuais>
                            <p>Coleta de Documentos</p>
                        </BoxContratuais>

                        <BoxContratuais>
                            <Contratuais>
                                <Levantamento style={{ width: "50%", height: "100%", margin: "0 auto" }} />
                            </Contratuais>
                            <p>Levantamento Financeiro</p>
                        </BoxContratuais>
                    </Row>
                    <Row>
                        <BoxContratuais>
                            <Contratuais>
                                <Apresentacao style={{ width: "50%", height: "100%", margin: "0 auto" }} />
                            </Contratuais>
                            <p>Apresentação do Projeto</p>
                        </BoxContratuais>

                        <BoxContratuais>
                            <Contratuais>
                                <Implantacao style={{ width: "50%", height: "100%", margin: "0 auto" }} />
                            </Contratuais>
                            <p>Implantação do Projeto</p>
                        </BoxContratuais>

                        <BoxContratuais>
                            <Contratuais>
                                <Deferimento style={{ width: "50%", height: "100%", margin: "0 auto" }} />
                            </Contratuais>
                            <p>Deferimento do Projeto</p>
                        </BoxContratuais>
                    </Row>

                    <hr
                        style={{
                            width: "100%",
                            height: "5px",
                            backgroundColor: "#3F3925",
                            border: "none",
                            borderRadius: "10px",
                            margin: "50px 0 50px 0",
                        }}
                    />

                    <TextIconComponent
                        style={{ margin: "50px 0 50px 0", textAlign: "Justify" }}
                        text={
                            "*As etapas contratuais terão início após a aprovação do projeto e assinatura do contrato de serviço entre as partes."
                        }
                    />

                    <Contato>
                        < Whatsapp
                            style={{ width: "20%", height: "10%", margin: "0 auto", cursor: 'pointer' }}
                            onClick={() => window.open("https://api.whatsapp.com/send/?phone=5565981154574&text&type=phone_number&app_absent=0")}
                        />
                        <h3>Precisa de suporte?</h3>
                        <h4>
                            Clique no botão acima para falar com um de nossos técnicos.
                        </h4>
                    </Contato>
                </BoxStyle>
            </Box>
        </Container>
    );
}
