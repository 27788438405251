import { useEffect, useState } from "react";
import { AiOutlineInfoCircle, AiOutlineShop } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { LuMapPin } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonComponent from "../../common/component/Button";
import CardComponent from "../../common/component/Card";
import MapChart from "../../common/component/Mapa";
import TextIconComponent from "../../common/component/TextIcon";
import dataOportunidades from "../../data/oportunidades";
import {
    ADMINITRATIVAS,
    Box,
    BoxStyle,
    Card,
    Container,
    Estadual,
    Federal,
    Graf,
    JUDICIAIS,
    LucroPresumido,
    Oportunidade,
    Total,
    ContainerMapa,
    BoxDonutChart,
    BoxMapa,
} from "./style";
import { Grafico } from "../../common/component/Grafico";
import { pathPrefix } from "../../data/pathPrefix";
import useQuery from "../../hooks/useQuery";

export default function Step03() {
    const navigate = useNavigate();
    const location = useLocation();
    const { dados, regimeTributario, project_id } = location?.state || {};
    const [oportunidades, setOportunidades] = useState({});

    const queryParams = useQuery()

    const formatCnpj = (cnpj) => {
        return `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(5, 8)}/${cnpj.substring(8, 12)}-${cnpj.substring(12, 14)}`;
    };

    const calculaOportunidades = (regimeTributario) => {
        let regime = "";
        let oportunidadesFinal = {
            Administrativa: {
                Federal: {
                    Total: 0,
                    Impostos: [],
                },
                Estadual: {
                    Total: 0,
                    Impostos: [],
                },
            },
            Judicial: {
                Federal: {
                    Total: 0,
                    Impostos: [],
                },
                Estadual: {
                    Total: 0,
                    Impostos: [],
                },
            },
        };

        switch (regimeTributario) {
            case "1":
                regime = "LP";
                break;
            case "2":
                regime = "LR";
                break;
            case "3":
                regime = "SN";
                break;
            default:
                return;
        }

        dataOportunidades.forEach((op) => {
            if (!op.RegimeTributario.includes(regime)) return;

            ["Administrativa", "Judicial"].forEach((demanda) => {
                ["Federal", "Estadual"].forEach((jurisdicao) => {
                    if (op.Demanda === demanda && op.Jurisdicao === jurisdicao) {
                        if (
                            !oportunidadesFinal[demanda][jurisdicao].Impostos.includes(
                                op.Impostos
                            )
                        )
                            oportunidadesFinal[demanda][jurisdicao].Impostos.push(
                                op.Impostos
                            );

                        oportunidadesFinal[demanda][jurisdicao].Total += 1;
                    }
                });
            });
        });

        setOportunidades(oportunidadesFinal);
    };

    useEffect(() => {
        calculaOportunidades(regimeTributario);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regimeTributario]);

    return (
        <Container>
            <Box>
                <Card>Análise preliminar</Card>
                <BoxStyle>
                    <CardComponent texto={"Informações da empresa"} />

                    <TextIconComponent
                        style={{ justifyContent: "flex-start" }}
                        text={dados?.razao_social || "Não encontrado"}
                        icon={<AiOutlineShop />}
                    />
                    <TextIconComponent
                        style={{ justifyContent: "flex-start" }}
                        text={`${dados?.cnpj ? formatCnpj(dados?.cnpj) : "Não encontrado"}`}
                        icon={<AiOutlineInfoCircle />}
                    />
                    <TextIconComponent
                        style={{ justifyContent: "flex-start" }}
                        text={dados?.endereco.estado.nome || "Não encontrado"}
                        icon={<LuMapPin />}
                    />
                    <LucroPresumido>
                        {regimeTributario
                            ? regimeTributario === "1"
                                ? "LUCRO PRESUMIDO"
                                : regimeTributario === "2"
                                    ? "LUCRO REAL"
                                    : "SIMPLES NACIONAL"
                            : "Não encontrado"}
                    </LucroPresumido>

                    {Number(regimeTributario) === 3 && (
                        <ButtonComponent
                            onClick={() => {
                                const prefix = pathPrefix()

                                navigate(
                                    {
                                        pathname: prefix + "/iniciar/calculo",
                                        search: queryParams.toString()
                                    },
                                    {
                                        state: {
                                            project_id,
                                            regimeTributario
                                        },
                                    }
                                )
                            }}
                            text={"💰SIMULE SEUS BENEFÍCIOS"}
                            icon={<BsArrowRight />}
                        />
                    )}

                    {Number(regimeTributario) !== 3 && (
                        <ButtonComponent
                            onClick={() => {
                                const prefix = pathPrefix()

                                navigate(
                                    {
                                        pathname: prefix + "/iniciar/recuperacao",
                                        search: queryParams.toString()
                                    },
                                    {
                                        state: {
                                            project_id,
                                            regimeTributario
                                        },
                                    }
                                )
                            }}
                            text={"💰QUERO RECUPERAR"}
                            icon={<BsArrowRight />}
                        />
                    )}

                    <ContainerMapa>
                        <BoxMapa>
                            <MapChart className="mapa" sigla={dados?.endereco.estado.sigla} />
                        </BoxMapa>
                        <BoxDonutChart>
                            <Oportunidade>
                                <span>OPORTUNIDADES ADMINITRATIVAS</span>
                                <ADMINITRATIVAS>
                                    <Graf>
                                        {" "}
                                        <Grafico
                                            data={[
                                                ["", ""],
                                                [
                                                    `Federal ${oportunidades?.Administrativa?.Federal?.Total}`,
                                                    oportunidades?.Administrativa?.Federal?.Total,
                                                ],
                                                [
                                                    `Estadual ${oportunidades?.Administrativa?.Estadual?.Total}`,
                                                    oportunidades?.Administrativa?.Estadual?.Total,
                                                ],
                                            ]}
                                        />
                                    </Graf>

                                    <Total>
                                        {oportunidades?.Administrativa?.Federal?.Total +
                                            oportunidades?.Administrativa?.Estadual?.Total}
                                    </Total>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Federal>
                                            <h2>{`Federal (${oportunidades?.Administrativa?.Federal?.Total})`}</h2>
                                            <h3>
                                                {oportunidades?.Administrativa?.Federal?.Impostos.map(
                                                    (nome, index) => {
                                                        return (
                                                            <div key={index}>
                                                                {nome}
                                                                <br />
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </h3>
                                        </Federal>
                                        <Estadual>
                                            <h2>{`Estadual (${oportunidades?.Administrativa?.Estadual?.Total})`}</h2>
                                            <h3>
                                                {oportunidades?.Administrativa?.Estadual?.Impostos.map(
                                                    (nome, index) => {
                                                        return (
                                                            <div key={index}>
                                                                {nome}
                                                                <br />
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </h3>
                                        </Estadual>
                                    </div>
                                </ADMINITRATIVAS>
                            </Oportunidade>

                            <Oportunidade>
                                <span>OPORTUNIDADES JUDICIAIS</span>
                                <JUDICIAIS>
                                    <Graf>
                                        <Grafico
                                            data={[
                                                ["", ""],
                                                [
                                                    `Federal ${oportunidades?.Judicial?.Federal?.Total}`,
                                                    oportunidades?.Judicial?.Federal?.Total,
                                                ],
                                                [
                                                    `Estadual ${oportunidades?.Judicial?.Estadual?.Total}`,
                                                    oportunidades?.Judicial?.Estadual?.Total,
                                                ],
                                            ]}
                                        />
                                    </Graf>
                                    <Total>
                                        {oportunidades?.Judicial?.Federal?.Total +
                                            oportunidades?.Judicial?.Estadual?.Total}
                                    </Total>
                                    <div
                                        className="judicialbox"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Federal>
                                            <h2>{`Federal (${oportunidades?.Judicial?.Federal?.Total})`}</h2>
                                            <h3>
                                                {oportunidades?.Judicial?.Federal?.Impostos.map(
                                                    (nome, index) => {
                                                        return (
                                                            <div key={index}>
                                                                {nome}
                                                                <br />
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </h3>
                                        </Federal>
                                        <Estadual>
                                            <h2>{`Estadual (${oportunidades?.Judicial?.Estadual?.Total})`}</h2>
                                            <h3>
                                                {oportunidades?.Judicial?.Estadual?.Impostos.map(
                                                    (nome, index) => {
                                                        return (
                                                            <div key={index}>
                                                                {nome}
                                                                <br />
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </h3>
                                        </Estadual>
                                    </div>
                                </JUDICIAIS>
                            </Oportunidade>
                        </BoxDonutChart>
                    </ContainerMapa>
                    {Number(regimeTributario) !== 3 && (
                        <ButtonComponent
                            onClick={() => {
                                const prefix = pathPrefix()

                                navigate(
                                    {
                                        pathname: prefix + "/iniciar/recuperacao",
                                        search: queryParams.toString()
                                    },
                                    {
                                        state: {
                                            project_id,
                                            regimeTributario
                                        },
                                    }
                                )
                            }}
                            text={"💰QUERO RECUPERAR"}
                            icon={<BsArrowRight />}
                        />
                    )}

                    {Number(regimeTributario) === 3 && (
                        <ButtonComponent
                            onClick={() => {
                                const prefix = pathPrefix()

                                navigate(
                                    {
                                        pathname: prefix + "/iniciar/calculo",
                                        search: queryParams.toString()
                                    },
                                    {
                                        state: {
                                            project_id,
                                            regimeTributario
                                        },
                                    }
                                )
                            }}
                            text={"💰SIMULE SEUS BENEFÍCIOS"}
                            icon={<BsArrowRight />}
                        />
                    )}

                    <TextIconComponent
                        style={{ margin: "50px 0 50px 0", textAlign: "Justify" }}
                        text={
                            "*As informações contidas neste informe contemplam a análise técnica de mais de 1000 empresas de diversos seguimentos e regimes fiscais."
                        }
                    />
                    <TextIconComponent
                        style={{ margin: "50px 0 50px 0", textAlign: "Justify" }}
                        text={
                            "*Podem existir outras oportunidades passíveis de serem recuperadas."
                        }
                    />
                </BoxStyle>
            </Box>
        </Container>
    );
}
