import * as React from 'react'
import {Card, CardCenter, CardImportante, ImportantInfo} from "../Step-04/style";
import {BoxStyle} from "../Step-02/style";
import {Box, Container} from "../Step-01/style";
import {GiCalculator} from "react-icons/gi";
import {BsArrowRight} from "react-icons/bs";
import ButtonComponent from "../../common/component/Button";
import {useLocation, useNavigate} from "react-router-dom";
import TitleCard from "./titlecard";
import {useMediaQuery} from "react-responsive";
import {pathPrefix} from "../../data/pathPrefix";
import useQuery from "../../hooks/useQuery";


const numberFormat = (number) => {
    const formatter = Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    })

    return formatter.format(number)
}

export const Step04CalculadoraResultado = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { project_id, restituido, economiaMensal } = location?.state || {};

    const queryParams = useQuery()

    const xs = useMediaQuery({ query: '(max-width: 350px)' })
    const sm = useMediaQuery({ query: '(min-width: 350px) and (max-width: 450px)' })
    const md = useMediaQuery({ query: '(min-width: 450px) and (max-width: 720px)' })
    // const lg = useMediaQuery({ query: '(min-width: 720px)' })

    const resultSize = xs
        ? '25px'
        : sm
            ? '30px'
            : md ? '40px' : '60px'

    return (
        <Container>
            <Box>
                <Card>Análise preliminar</Card>
                <BoxStyle>
                    <CardCenter>
                        <CardImportante style={{ padding: '1rem' }}>
                            <GiCalculator />
                            <h3 style={{ textAlign: 'center', marginTop: '0.5rem' }}>
                                Simulador de Crédito
                            </h3>
                        </CardImportante>
                    </CardCenter>

                    { !restituido && (
                        <TitleCard title={'Crédito Apurado'}>
                            <div style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '10px' }}>
                                <p style={{ fontSize: '30px', color: 'white', margin: "30px 0" }}>
                                    Com base no valor informado sua empresa não se enquadra no simples nacional
                                </p>
                            </div>
                        </TitleCard>
                    ) }

                    {restituido && (
                        <>
                            <TitleCard title={'Crédito Apurado'}>
                                <div style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '10px' }}>
                                    <p style={{ fontSize: resultSize, color: '#50C863', margin: "1rem 0" }}>
                                        { numberFormat(restituido) }
                                    </p>
                                </div>
                            </TitleCard>

                            <TitleCard title={'Economia Mensal'}>
                                <div style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '10px' }}>
                                    <p style={{ fontSize: resultSize, color: '#F5E4BC', margin: "1rem 0" }}>
                                        { numberFormat(economiaMensal) }
                                    </p>
                                </div>
                            </TitleCard>

                            <ImportantInfo>
                                <h3 style={{ textAlign: 'center' }}>
                                    O valor financeiro destacado poderá estar disponível em até 90 dias na conta corrente bancaria da empresa.
                                </h3>
                            </ImportantInfo>
                        </>
                    )}

                    <ButtonComponent
                        onClick={
                            () => {
                                const prefix = pathPrefix()
                                navigate(
                                    {
                                        pathname: prefix + '/informe/tributario',
                                        search: queryParams.toString()
                                    },
                                    {
                                        state: {project_id}
                                    }
                                )
                            }
                        }
                        text={"INICIAR RECUPERAÇÃO"}
                        icon={<BsArrowRight />}
                    />
                </BoxStyle>
            </Box>
        </Container>
    )
}

export default Step04CalculadoraResultado
