import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: auto;
  padding : 25px;
  gap: 25px;
  font-family: 'inter', sans-serif;
  
  .loading-spinner {
    border: 16px solid #2c2a1f;
    border-radius: 50%;
    border-top: 16px solid #ac8d4c;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;

    @media screen and (max-width: 800px) {
      width: 80px;
      height: 80px;
    }

    
  }
  .custom-loader {
  width:200px;
  height:200px;
  border-radius:50%;
  background:conic-gradient(#0000 10%,#ac8d4c);
  -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 16px),#000 0);
  mask:radial-gradient(farthest-side,#0000 calc(100% - 16px),#000 0);
  animation:s3 1s infinite linear;
}
@keyframes s3 {to{transform: rotate(1turn)}}

  .loading-text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 600px;
    min-height: 50px;
    padding: 1rem;
    text-align: center;
    border-radius: 5px;
    border: 2px solid #ac8d4c;
    z-index: 1;
    font-size: 1.5rem;
    font-weight: 600;
    color: #AC8D4C;
    margin: 1rem 0rem;
    font-family: 'inter', sans-serif;

    /* @media screen and (max-width: 800px) {
      max-width: 300px;
      height: 100px;
    } */

    &:after {
      max-width: 630px;
      box-sizing: border-box;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: #d2cb991f;
      ${(props) => !props.textFinish ? `
        width: ${(630 / 100) * props.loadingValue}px;
        ${props.loadingValue > 0 ? `-webkit-transition: width ${2 / props.tamanhoLista}s ease-in-out; ` : null}
        ${props.loadingValue > 0 ? `-moz-transition: width ${2 / props.tamanhoLista}s ease-in-out; ` : null}
        ${props.loadingValue > 0 ? `-o-transition: width ${2 / props.tamanhoLista}s ease-in-out; ` : null}
        ${props.loadingValue > 0 ? `transition: width ${2 / props.tamanhoLista}s ease-in-out; ` : null}
      ` : `
        width: 100%;
      `}
      
      
      
      
      
      border-radius: 5px;
      border: 1px solid #ac8d4c;
    }
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    90% {
      -webkit-transform: rotate(360deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .texto-loading {
    display: flex;
    height: 50px;
    border-radius: 5px;
    align-items: center;
    padding: 1rem;
    max-width: 500px;
    position: relative;
    top: -110px;
    //width: ${(props) => (500 / 100) * props.loadingValue}px;
    -webkit-transition: width 2s ease-in-out;
    -moz-transition: width 2s ease-in-out;
    -o-transition: width 2s ease-in-out;
    transition: width 2s ease-in-out;
    width: 100%;
    margin: 0 0 0px 67px;
    max-width: 700px;
    height: 53px;

    &:after {
      max-width: 500px;
      box-sizing: border-box;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: #d2cb990c;
      width: ${(props) => (500 / 100) * props.loadingValue}px;
      -webkit-transition: width 2s ease-in-out;
      -moz-transition: width 2s ease-in-out;
      -o-transition: width 2s ease-in-out;
      transition: width 2s ease-in-out;
      border-radius: 5px;
      border: 1px solid #ac8d4c;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
      margin: 0 0 0px 56px;
      max-width: 500px;
      height: 90px;

      &:after {
        max-width: 500px;
        box-sizing: border-box;
        content: "";
        position: absolute;
        top: -50px;
        bottom: 38px;
        left: 15px;
        background-color: green;
        width: ${(props) => (500 / 100) * props.loadingValue}px;
        -webkit-transition: width 2s ease-in-out;
        -moz-transition: width 2s ease-in-out;
        -o-transition: width 2s ease-in-out;
        transition: width 2s ease-in-out;
        border-radius: 5px;
        border: 1px solid #ac8d4c;
      }
    }
  }
`;
