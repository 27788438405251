import { styled } from "styled-components";

export const TitleSteps = styled.h2`
  font-size: 28px;
  @media screen and (max-width: 900px) {
    font-size: 24px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: auto;
  // height: 230px;
  background-color: #3f3925;
  color: #ac8d4c;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 35px;
  font-family: 'inter', sans-serif;
  svg {
    font-size: 50px;
    margin: auto 0 auto 0;
    @media screen and (max-width: 768px) {
      font-size: 110px;
    }
  }

`;

export const BoxStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 0 30px;
  font-family: 'inter', sans-serif;
  @media screen and (max-width: 768px) {
    padding: 0px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const Card = styled.div`
  border-radius: 50%;
  display: inline-block;
  height: 100px;
  width: 100px;
  border: 1px solid #ac8d4c;
  box-sizing: border-box;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
    svg {
      font-size: 50px;
      color: #ac8d4c;
    }



  @media screen and (max-width: 768px) {
    height: 50px;
    width: 50px;
    border: 1px solid #ac8d4c;
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    img {
      width: 50%;
    }
  }
`;

export const CardFixa = styled.div`
  border-radius: 50%;
  display: inline-block;
  height: 100px;
  width: 100px;
  border: 1px solid #ac8d4c;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 50%;
    -webkit-box-shadow: 0px 0px 27px 2px rgba(172, 142, 76, 1);
    -moz-box-shadow: 0px 0px 27px 2px rgba(172, 142, 76, 1);
    box-shadow: 0px 0px 27px 2px rgba(172, 142, 76, 1);
  }
  @media screen and (max-width: 768px) {
    height: 50px;
    width: 50px;
    border: 1px solid #ac8d4c;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50%;
    }
  }
`;

export const Box = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: center;
  width: 20%;
  justify-content: center;
  flex-direction: column;
  color: #ac8d4c;
  font-family: 'inter', sans-serif;
  p {
    font-size: 16px;
    font-weight: bold;
    min-height: 50px;
    color: #ac8d4c;
    margin-top: 10px;
    font-family: 'inter', sans-serif;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    p {
      font-size: 11px;
      font-weight: bold;
      color: #ac8d4c;
      margin-top: 10px;
      font-family: 'inter', sans-serif;
    }
  }

`;
