import * as React from "react";

export const TitleCard = ({ title, children }) => {
    return (
        <div style={{
            borderRadius: '5px',
            backgroundColor: '#3F3925',
            padding: '30px',
            paddingBottom: 0 }}
        >
            <div style={{
                textTransform: 'uppercase',
                backgroundColor: '#50462A',
                padding: '10px',
                borderRadius: '5px',
                color: "#AC8D4C",
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '18px'
            }}>
                <p>{title}</p>
            </div>
            {children}
        </div>
    )
}

export default TitleCard
