import React from "react";
import { Cartao } from "./style";

export default function CardComponent({ texto }) {
    return (
        <Cartao>
            <p> {texto} </p>
        </Cartao>
    )
}
