import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0 25px;
  box-sizing: border-box;
  align-items: center;
  background-color: #2c2a1f;
  font-family: 'inter', sans-serif;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  max-width: 800px;
  flex-direction: column;
  align-items: center;
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ac8d4c;
  border-radius: 0 0 10px 10px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
`;

export const BoxStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 10px;
  box-sizing: border-box;
  margin-bottom: 50px;
`;

export const Button = styled.button`
  display: flex;
  width: 50%;
  height: 70px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  color: black;
  font-size: 25px;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: green;
  }
`;

export const ImportantInfo = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  gap: 10px;
  text-align: justify;
  font-family: "Inter", sans-serif;
  line-height: 1.5;
  box-sizing: border-box;
  padding: 0 10px;
  h3 {
    font-weight: 400;
  }
`;

export const CardCenter = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 40px;
  background-color: rgba(127, 105, 59, 0.2);
  box-sizing: border-box;
  border-radius: 6px;
`;

export const CardImportante = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 40px;
  border-radius: 5px;
  font-family: 'inter', sans-serif;

  img {
    width: 100px;
    height: 100px;
  }
  h3 {
    font-size: 25px;
    font-weight: bold;
    margin: 10;
    color: #ac8d4c;
  }
  h4 {
    font-size: 20px;
    font-weight: bold;
    margin: 10;
    color: white;
  }
`;

export const Contratuais = styled.div`
  border-radius: 50%;
  display: inline-block;
  height: 150px;
  width: 150px;
  border: 1px solid white;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ac8d4c;
  img {
    width: 50%;
  }
  @media screen and (max-width: 800px) {
    width: 100px;
    height: 100px;
  }
`;

export const BoxContratuais = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: center;
  width: 50%;
  flex-direction: column;
  font-family: 'inter', sans-serif;
  p {
    font-size: 16px;
    font-weight: bold;
    color: #ac8d4c;
    margin-top: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  box-sizing: border-box;
  margin-top: 20px;
  padding: 0 50px;
  @media (max-width: 800px) {
    width: 100%;
    align-items: center;
    padding: 0;
  }
`;

export const Cartao = styled.div`
  display: flex;
  width: 97%;
  height: 25px;
  background-color: #3f3925;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  color: #ac8d4c;
  font-size: 16px;
  padding: 10px;
  margin-top: 30px;
  font-family: 'inter', sans-serif;
  p {
    font-size: 50px;
    display: flex;
    align-items: center;
    margin: 0;
  }
`;

export const Contato = styled.div`
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  box-sizing: border-box;
  border: 1px solid #8592a2;
  border-radius: 6px;
  flex-direction: column;
  padding: 20px;
  font-family: 'inter', sans-serif;

  h3 {
    font-size: 25px;
    font-weight: bold;
    margin: 10;
    color: #8592a2;
  }
  h4 {
    font-size: 20px;
    font-weight: bold;
    margin: 10;
    color: #8592a2;
  }
  img {
    width: 100px;
    height: 100px;
  }
`;

export const Whats = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: space-around;
  background-color: #08e430;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  font-size: 25px;
    @media screen and (max-width: 600px) {
    width: 100%;
    height: 50px;
    justify-content: space-around;
    background-color: #08e430;
    align-items: center;
    box-sizing: border-box;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    font-size:14px;
  }


`;
