import React from "react";
import { Chart } from "react-google-charts";

export const options = {
    pieHole: 0.64,
    is3D: false,
    legend: "none", // "bottom", "labeled", "left", "none", "right", "top"
    pieSliceText: "percent", // "value", "label", "percent", "none
    'width': 200,
    'height': 150,

    colors: ['#8C6920', '#AC8D4C'],
    chartArea: {
        left: 0,
        right: 105,
        top: 0,
        width: "100%",
        height: "95%",
    },
    fontSize: 18,
    backgroundColor: "transparent",
    pieSliceBorderColor: "transparent",
    pieSliceTextStyle: {
        color: "white",
        fontSize: 18,
    },
    alignment: "center", // "start", "center", "end"

};

export function Grafico({ data }) {
    return (
        <Chart
            chartType="PieChart"
            width="100%"
            data={data}
            options={options}
        />
    );
}
