import styled from "styled-components";


export const TextIcon = styled.div`
    display: flex;
    width: 100%;
    height: 20px;
align-items: center;
    color: white;
   p{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 20px;
      margin-right: 10px;
    }
  }
`;