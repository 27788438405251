import { Box, BoxStyle, Card, CardFixa, Container, TitleSteps } from "./style";
import { MdOutlineNavigateNext } from "react-icons/md";
// import Proposta from "../../images/asses/Proposta.svg";
// import termo from "../../../images/asses/Termo.svg";
// import questionario from "../../../images/asses/Questionario.svg";
// import analise from "../../../images/asses/Analise.svg";
import { ReactComponent as Proposta } from "../../component/svg/Proposta.svg";
import { ReactComponent as Termo } from "../../component/svg/Termo.svg";
import { ReactComponent as Questionario } from "../../component/svg/Questionario.svg";
import { ReactComponent as Analise } from "../../component/svg/Analise.svg";

export default function Etapas() {
    return (
        <>
            <Container>
                <TitleSteps >Etapas Preliminares</TitleSteps>
                <BoxStyle>
                    <Box>
                        <Card>
                            <Proposta
                                alt="proposta"
                                style={{ width: "40%", height: "100%", margin: "0 auto" }}
                            />
                        </Card>
                        <p>Proprosta de Serviço</p>
                    </Box>
                    <MdOutlineNavigateNext />
                    <Box>
                        <Card>
                            <Termo
                                style={{ width: "40%", height: "100%", margin: "0 auto" }}
                            />
                        </Card>
                        <p>Termo de Confidencialidade</p>
                    </Box>
                    <MdOutlineNavigateNext />
                    <Box>
                        <CardFixa>
                            <Questionario
                                style={{ width: "40%", height: "100%", margin: "0 auto" }}
                            />
                        </CardFixa>
                        <p>Questionário Preliminar</p>
                    </Box>
                    <MdOutlineNavigateNext />
                    <Box>
                        <Card>
                            <Analise
                                style={{ width: "40%", height: "100%", margin: "0 auto" }}
                            />
                        </Card>
                        <p> Análise Técnica</p>
                    </Box>
                </BoxStyle>
            </Container>
        </>
    );
}
