import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0 25px;
  box-sizing: border-box;
  align-items: center;
  background-color: #2c2a1f;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 800px;
  flex-direction: column;
  align-items: center;
  hr {
    width: 100%;
    border: 1px solid #ac8d4c;
    margin-top: 50px;
  }
  img {
    width: 100%;
    max-width: 150px;
    margin-top: 50px;
  }
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ac8d4c;
  border-radius: 0 0 10px 10px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
`;

export const Interacao = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #ac8d4c;
  font-size: 30px;
  font-weight: bold;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  text-align: justify;
  color: white;
  font-size: 17px;
  p {
    margin: 0;
    font-family: 'inter', sans-serif;
  }

  span {
    text-decoration: underline;
    font-family: 'inter', sans-serif;
  }
`;

export const Input = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  color: white;
  margin-top: 30px;

  p {
    text-align: center;
    font-family: 'inter', sans-serif;
    margin-top: -22px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 14px;
    font-family: 'inter', sans-serif;

    svg {
      font-size: 20px;
      margin-right: 10px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 10px;
    font-family: 'inter', sans-serif;
  }
  .copy {
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: 'inter', sans-serif;
  }
`;
