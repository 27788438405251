const oportunidades = [
    {
        "Impostos": "INSS",
        "Oportunidade": "Verbas de Caráter Indenizatórias",
        "Demanda": "Administrativa",
        "RegimeTributario": "LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Exclusão do ICMS da base de cálculo",
        "Demanda": "Administrativa",
        "RegimeTributario": "LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "IRPJ",
        "Oportunidade": "Retenções na Fonte",
        "Demanda": "Administrativa",
        "RegimeTributario": "LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "INSS",
        "Oportunidade": "FAP (Fator Acidentário de Prevenção)",
        "Demanda": "Administrativa",
        "RegimeTributario": "LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "ICMS",
        "Oportunidade": "PRODEIC",
        "Demanda": "Administrativa",
        "RegimeTributario": "LP, LR",
        "Jurisdicao": "Estadual"
    },
    {
        "Impostos": "IRPJ | CSLL",
        "Oportunidade": "Deduções sobre as Receitas Brutas",
        "Demanda": "Administrativa",
        "RegimeTributario": "LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Exclusões sob vendas canceladas",
        "Demanda": "Administrativa",
        "RegimeTributario": "LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Exclusões sob descontos nas vendas",
        "Demanda": "Administrativa",
        "RegimeTributario": "LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS | IRPJ | IPI | CSLL | INSS",
        "Oportunidade": "Transação Extraordinária",
        "Demanda": "Administrativa",
        "RegimeTributario": "LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Exclusão ICMS-ST da base de cálculo",
        "Demanda": "Judicial",
        "RegimeTributario": "LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "INSS",
        "Oportunidade": "Contribuições para Terceiros",
        "Demanda": "Judicial",
        "RegimeTributario": "LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "IRPJ | CSLL",
        "Oportunidade": "Exclusão do ICMS da base de cálculo",
        "Demanda": "Judicial",
        "RegimeTributario": "LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Exclusão do ISSQN da base de cálculo",
        "Demanda": "Judicial",
        "RegimeTributario": "LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "IRPJ | CSLL",
        "Oportunidade": "Exclusão do ISSQN da base de cálculo",
        "Demanda": "Judicial",
        "RegimeTributario": "LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "IRPJ | CSLL",
        "Oportunidade": "Subvenção para Investimento",
        "Demanda": "Administrativa",
        "RegimeTributario": "LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "ICMS",
        "Oportunidade": "Frete FOB",
        "Demanda": "Administrativa",
        "RegimeTributario": "LR",
        "Jurisdicao": "Estadual"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Depreciações",
        "Demanda": "Administrativa",
        "RegimeTributario": "LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "IRPJ",
        "Oportunidade": "Juros sobre Capital Próprio",
        "Demanda": "Administrativa",
        "RegimeTributario": "LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Insumos",
        "Demanda": "Administrativa",
        "RegimeTributario": "LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Combustíveis",
        "Demanda": "Administrativa",
        "RegimeTributario": "LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Bonificações",
        "Demanda": "Administrativa",
        "RegimeTributario": "LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Crédito - Atividade Comercial - Revenda de Itens",
        "Demanda": "Administrativa",
        "RegimeTributario": "LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Crédito - Energia Elétrica",
        "Demanda": "Administrativa",
        "RegimeTributario": "LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Crédito - Supermercado - Embalagem (Açougue / Padaria)",
        "Demanda": "Administrativa",
        "RegimeTributario": "LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Crédito - Aluguel",
        "Demanda": "Administrativa",
        "RegimeTributario": "LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Crédito - Atividade Comercial - Combustível",
        "Demanda": "Judicial",
        "RegimeTributario": "LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Regime Monofásico e ST",
        "Demanda": "Administrativa",
        "RegimeTributario": "SN, LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Cigarros",
        "Demanda": "Administrativa",
        "RegimeTributario": "SN, LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Revenda - Tributação Indevida",
        "Demanda": "Administrativa",
        "RegimeTributario": "SN, LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "SIMPLES - Revenda - Tributação Monofásica",
        "Demanda": "Administrativa",
        "RegimeTributario": "SN, LP, LR",
        "Jurisdicao": "Federal"
    },
    {
        "Impostos": "PIS | COFINS",
        "Oportunidade": "Exclusão do PIS e da COFINS da sua base",
        "Demanda": "Judicial",
        "RegimeTributario": "SN, LP, LR",
        "Jurisdicao": "Federal"
    }
]

export default oportunidades;