import * as React from 'react'
import {Card, CardCenter, CardImportante, ImportantInfo} from "../Step-04/style";
import {BoxStyle} from "../Step-02/style";
import {Box, Container} from "../Step-01/style";
import {GiCalculator} from "react-icons/gi";
import CardComponent from "../../common/component/Card";
import SelectComponent from "../../common/component/Select";
import {Form} from "antd";
import {Cartao} from "../../common/component/Card/style";
import CurrencyInput from "../../common/component/CurrencyInput";
import {BsArrowRight} from "react-icons/bs";
import ButtonComponent from "../../common/component/Button";
import { simulateTributeRecoverCalculation } from './calculate'
import {useLocation, useNavigate} from "react-router-dom";
import {pathPrefix} from "../../data/pathPrefix";
import useQuery from "../../hooks/useQuery";

const segmentoList = [
    {id: 0, name: 'Selecione seu segmento', value: 0.0},
    {id: 1, name: 'Loja de Conveniência', value: 0.7},
    {id: 2, name: 'Autopeças', value: 0.8},
    {id: 3, name: 'Casa de Show', value: 0.25},
    {id: 3, name: 'Bares/Restaurantes', value: 0.25},
    {id: 4, name: 'Centro Automotivo', value: 0.5},
    {id: 5, name: 'Farmácias', value: 0.9},
    {id: 6, name: 'Mercados/Mercearias', value: 0.10},
    {id: 7, name: 'Perfumaria', value: 0.85},
    {id: 8, name: 'Casa agropecuária / Petshop', value: 0.3},
    {id: 9, name: 'Outros', value: 0.25},
]

export const Step04Calculadora = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { project_id } = location?.state || {};

    const [segmento, setSegmento] = React.useState(0)
    const [faturamento, setFaturamento] = React.useState(0)

    const queryParams = useQuery()

    const executeCalculation = () => {
        if (segmento === 0 || faturamento === 0) {
            return
        }

        const segmentoPeso = segmentoList.find(each => each.id === Number(segmento))?.value
        const { restituido, economiaMensal } = simulateTributeRecoverCalculation(faturamento, segmentoPeso)

        const prefix = pathPrefix()

        navigate(
            {
                pathname: prefix + '/iniciar/calculo/resultado',
                search: queryParams.toString()
            },
            {
                state: { project_id, restituido, economiaMensal }
            }
        )
    }

    return (
        <Container>
            <Box>
                <Card>Análise preliminar</Card>
                <BoxStyle>
                    <CardCenter>
                        <CardImportante style={{ padding: '1rem' }}>
                            <GiCalculator />
                            <h3 style={{ textAlign: 'center' }}>
                                Simulador de Crédito
                            </h3>
                        </CardImportante>
                    </CardCenter>

                    <ImportantInfo>
                        <h3>
                            Informe os dados abaixo para gerar sua simulação:
                        </h3>
                    </ImportantInfo>

                    <CardComponent texto={"Qual é o seu segmento?"} />

                    <Form onFinish={(e) => { executeCalculation() }}>
                        <Form.Item
                            name="segmento"
                            rules={[
                                {
                                    required: segmento === 0,
                                    message: "Por favor, selecione o seu segmento!",
                                },
                            ]}
                        >
                            <SelectComponent
                                onChange={(e) => setSegmento(e.target.value)}
                                placeholder={"Selecione"}
                                value={segmento}
                                defaultValue={segmento}
                                options={segmentoList.map(
                                    (each) => ({value: each.id, label: each.name})
                                )}
                                style={{
                                    backgroundColor: "#2c2a1f",
                                    color: "white",
                                }}
                            />
                        </Form.Item>

                        <Cartao style={{ marginTop: '10px' }}>
                            <p>Qual é o seu faturamento mensal?</p>
                        </Cartao>

                        <Form.Item
                            name={`valor`}
                            rules={[
                                {
                                    required: faturamento === 0,
                                    message: `Informe o seu faturamento mensal`,
                                },
                            ]}
                        >
                            <CurrencyInput onChange={(amount) => setFaturamento(amount)} defaultValue={0} />
                        </Form.Item>

                        <ButtonComponent type={"submit"}
                            // onClick={
                            //     () => navigate('/iniciar/calculo/resultado', { state: { project_id } })
                            // }
                            text={"Calcular Crédito"}
                            icon={<BsArrowRight />}
                        />
                    </Form>
                </BoxStyle>
            </Box>
        </Container>
    )
}

export default Step04Calculadora
