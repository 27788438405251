/* eslint-disable no-eval */
import { BsArrowRight } from "react-icons/bs";
import ButtonComponent from "../../common/component/Button";
import CardComponent from "../../common/component/Card";
import { Box, BoxStyle, Container, Card } from "./styled";
import { useLocation, useNavigate } from "react-router-dom";
import Etapas from "../../common/component/Steps";
import {useEffect, useState} from "react";
import { Form } from "antd";
import { Progress } from "antd";
import SelectComponent from "../../common/component/Select";
import {pathPrefix} from "../../data/pathPrefix";
import useQuery from "../../hooks/useQuery";

export default function Step06() {
    const location = useLocation();
    const navigate = useNavigate();
    const [faturaAno, setFaturaAno] = useState({});
    const { project_id, questionario } = location?.state || {};
    const classesOptions = {
        0: 'item1',
        1: 'item2',
        2: 'item3'
    };
    const [years, setYears] = useState([])

    const queryParams = useQuery()

    useEffect(() => {
        const currentYear = new Date().getFullYear()

        const years = []
        for (let i = currentYear - 2; i <= currentYear; i++) {
            years.push(i)
        }

        setYears(years)
    }, [setYears]);

    const handleFaturaAnoChange = (e) => {
        const { name, value } = e.target

        setFaturaAno(prev => ({
            ...prev,
            [name]: value
        }));

        const elementos = document.querySelectorAll('.' + classesOptions[years.indexOf(parseInt(name) + 1)]);

        if (elementos.length > 0) {
          elementos.forEach(elemento => {
            if (elemento.tagName.toLowerCase() === 'button') {
              elemento.style.display = 'flex';
            } else {
              elemento.style.display = 'unset';
            }
          });
        } else {
          console.log('Nenhum elemento encontrado com a classe desejada.');
        }
    };

    const options = [
        { value: "0", label: "Selecione" },
        { value: "Até R$500.000,00", label: "Até R$500.000,00" },
        { value: "Até R$1.000.000,00", label: "Até R$1.000.000,00" },
        { value: "Até R$3.000.000,00", label: "Até R$3.000.000,00" },
        { value: "Até R$5.000.000,00", label: "Até R$5.000.000,00" },
        { value: "Até R$10.000.000,00", label: "Até R$10.000.000,00" },
        { value: "Acima de R$10.000.000,00", label: "Acima de R$10.000.000,00" },
    ];

    const onFinish = (values) => {
        const faturamentos = Object.keys(faturaAno).map(each => {
            return {type: 'text', label: `Faturamento ${each}`, value: faturaAno[each]}
        })

        const prefix = pathPrefix()

        navigate(
            {
                pathname: prefix + '/informe/valor',
                search: queryParams.toString()
            },
            {
                state: {
                    project_id,
                    questionario,
                    faturamento: faturamentos
                }
            }
        )
    };

    return (
        <Form
            onFinish={(e) => {
                onFinish(e);
            }}
        >
            <Container>
                <Box>
                    <Card>Questionário Preliminar</Card>

                    <BoxStyle>
                        <Etapas />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                margin: " 0",
                            }}
                        >
                            <Progress
                                percent={30}
                                showInfo={false}
                                strokeColor={{
                                    "0%": "#50c863",
                                    "100%": "#50c863",
                                }}
                                trailColor={"#e5e5e5"}
                                style={{ width: "100%", marginBottom: "1rem", height: "0px" }}
                            />
                            <p>Progresso</p>
                        </div>
                        <CardComponent texto={"Informe o faturamento bruto da empresa"} />

                        {years.map((ano, index) => {
                            return (
                                <div key={index} className={classesOptions[index]} style={{ display: index === 0 ? "unset" : "none" }}>
                                    <h3>
                                        EXERCÍCIO {ano}
                                        <b>*</b>
                                    </h3>
                                    <Form.Item
                                        name={`faturamento${ano}`}
                                        rules={[
                                            {
                                                required: true,
                                                message: `Informe o faturamento bruto de ${ano}`,
                                            },
                                        ]}
                                    >
                                        <SelectComponent
                                            onChange={handleFaturaAnoChange}
                                            placeholder={"Selecione"}
                                            value={faturaAno}
                                            options={options}
                                            name={ano}
                                            style={{
                                                color: "black",
                                                height: "70px",
                                                border: "1px solid #000",
                                                borderRadius: "6px",
                                            }}
                                            required
                                        />
                                    </Form.Item>
                                </div>
                            );
                        })}

                        <ButtonComponent
                            className="item3"
                            type="submit"
                            text={"PRÓXIMO"}
                            icon={<BsArrowRight />}
                        />
                    </BoxStyle>
                </Box>
            </Container>
        </Form>
    );
}
