import { BsArrowRight } from "react-icons/bs";
import CardComponent from "../../common/component/Card";
import { Box, BoxStyle, Container, Card } from "./styled";
import ButtonComponent from "../../common/component/Button";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Etapas from "../../common/component/Steps";
import { useState } from "react";
import { Form } from "antd";
import { Progress } from "antd";
import SelectComponent from "../../common/component/Select";
import {pathPrefix} from "../../data/pathPrefix";

export default function Step07() {
    const navigate = useNavigate();
    const [averageAmount, setAverageAmount] = useState("");
    const location = useLocation();
    const { project_id, questionario, faturamento } = location?.state || {};

    const queryParams = useParams()

    const options = [
        { value: "0", label: "Selecione" },
        { value: "Até R$15.000", label: "Até R$15.000" },
        { value: "Até R$ 30.000", label: "Até R$ 30.000" },
        { value: "Até R$ 60.000", label: "Até R$ 60.000" },
        { value: "Acima de R$60.000", label: "Acima de R$60.000" },
    ];

    const handleFaturaValor = (e) => {
        const selectedOption = e.target.value;
        setAverageAmount(selectedOption)
    };

    const onFinish = (values) => {
        const prefix = pathPrefix()

        navigate(
            {
                pathname: prefix + "/beneficio/fiscal",
                search: queryParams.toString()
            },
            {
                state: {
                    project_id,
                    questionario,
                    faturamento,
                    valor12meses: { averageAmount }
                },
            }
        );
    }

    return (
        <Form onFinish={onFinish}>
            <Container>
                <Box>
                    <Card>Questionário Preliminar</Card>
                    <BoxStyle>
                        <Etapas />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                margin: " 0",
                            }}
                        >
                            <Progress
                                percent={40}
                                showInfo={false}
                                strokeColor={{
                                    "0%": "#50c863",
                                    "100%": "#50c863",
                                }}
                                trailColor={"#e5e5e5"}
                                style={{ width: "100%", marginBottom: "1rem", height: "0px" }}
                            />
                            <p>Progresso</p>
                        </div>
                        <CardComponent texto={"Informe o valor médio da folha de pagamento da empresa (Últimos 12 meses):"} />
                        <h4>Qual o valor aproximado? <b>*</b></h4>

                        <div>
                            <Form.Item
                                name="average_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: `Informe o valor médio da folha de pagamento da empresa`,
                                    },
                                ]}
                            >
                                <SelectComponent
                                    onChange={handleFaturaValor}
                                    placeholder={"Selecione"}
                                    value={averageAmount}
                                    options={options}
                                    style={{
                                        color: "black",
                                        height: "70px",
                                        border: "1px solid #000",
                                        borderRadius: "6px",
                                    }}
                                    required
                                />
                            </Form.Item>
                        </div>


                        <ButtonComponent
                            type="submit"
                            text="PRÓXIMO"
                            icon={<BsArrowRight />}
                        />
                    </BoxStyle>
                </Box>
            </Container>
        </Form>
    );
}
