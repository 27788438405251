import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0 25px;
  box-sizing: border-box;
  align-items: center;
  background-color: #2c2a1f;
`;
export const Box = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  max-width: 800px;
  flex-direction: column;
  align-items: center;

`;
export const BoxStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: white;
  gap: 25px;
  box-sizing: border-box;
  b {
    color: red;
    font-family: 'inter', sans-serif;
  }
    
    h3 {  
    margin-top: 20px;
    margin-bottom: -10px;
    padding: 0;
    font-family: 'inter', sans-serif;
  }
  
`;
export const Card = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ac8d4c;
  border-radius: 0 0 10px 10px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
`;
