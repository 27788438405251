import styled from "styled-components";

export const Cartao = styled.div`
  display: flex;
  height: 25px;
  background-color: #3F3925;
  border-radius: 5px;
  color: #ac8d4c;
  font-size: 16px;
  padding: 10px;
  margin-top: 30px;
    p {
        display: flex;
        align-items: center;
        margin: 0;
        font-family: 'inter' !important;
    }
`;