import { BsArrowRight } from "react-icons/bs";
import CardComponent from "../../common/component/Card";
import { Box, BoxStyle, Container, Card, Button } from "./styled";
import ButtonComponent from "../../common/component/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox, Progress } from "antd";
import Etapas from "../../common/component/Steps";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {pathPrefix} from "../../data/pathPrefix";
import useQuery from "../../hooks/useQuery";

export default function Step13() {
    const navigate = useNavigate();
    const [activeSim, setActiveSim] = useState(false);
    const [activeNao, setActiveNao] = useState(false);
    const [outrasObservacoes, setOutrasObservacoes] = useState("");
    const [termoConfidencialidade, setTermoConfidencialidade] = useState("");
    const location = useLocation();
    const {
        project_id,
        questionario,
        faturamento,
        valor12meses,
        FiscalEstadual,
        RFB,
        recurperacaoTributariaJudicial,
        recurperacaoTributariaAdm,
        planejamentoTributario,
        auditoriaDigital,
    } = location?.state || {};

    const queryParams = useQuery()

    const handleYesClick = () => {
        setActiveSim(true);
        setActiveNao(false);
    };
    const handleNoClick = () => {
        setActiveSim(false);
        setActiveNao(true);
    };

    const body = {
        project_id: project_id,
        version: "1_0",
        data: [
            ...questionario,
            ...faturamento,
            {
                type: "text",
                label: "Informe o valor médio da folha de pagamento da empresa",
                value: valor12meses?.averageAmount || "Não informado",
            },
            {
                type: "checkbox",
                label: "A empresa possui algum benefício fiscal estadual?",
                value: FiscalEstadual?.activeSim ? ["Sim"] : ["Não"],
            },
            {
                type: "text",
                label: "A empresa possui algum benefício fiscal estadual? (Comentário)",
                value: FiscalEstadual?.comentarioFiscal || "Não informado",
            },
            {
                type: "checkbox",
                label: "A empresa possuí diferença no faturamento bruto anual com o informado à RFB?",
                value: RFB?.activeSim ? ["Sim"] : ["Não"],
            },
            {
                type: "text",
                label: "A empresa possuí diferença no faturamento bruto anual com o informado à RFB? (Comentário)",
                value: RFB?.faturamentoBruto || "Não informado",
            },
            {
                type: "checkbox",
                label: "A empresa já realizou alguma espécie de recuperação tributária judicial?",
                value: recurperacaoTributariaJudicial?.activeSim ? ["Sim"] : ["Não"],
            },
            {
                type: "text",
                label: "A empresa já realizou alguma espécie de recuperação tributária judicial? (Comentário)",
                value:
                    recurperacaoTributariaJudicial?.recurperacaoJudicial ||
                    "Não informado",
            },
            {
                type: "checkbox",
                label: "A empresa já realizou alguma espécie de recuperação tributária administrativa?",
                value: recurperacaoTributariaAdm?.activeSim ? ["Sim"] : ["Não"],
            },
            {
                type: "text",
                label: "A empresa já realizou alguma espécie de recuperação tributária administrativa? (Comentário)",
                value:
                    recurperacaoTributariaAdm?.recurperacaoAdm ||
                    "Não informado",
            },
            {
                type: "checkbox",
                label: "A empresa já realizou alguma espécie de planejamento tributário?",
                value: planejamentoTributario?.activeSim ? ["Sim"] : ["Não"],
            },
            {
                type: "text",
                label: "A empresa já realizou alguma espécie de planejamento tributário? (Comentário)",
                value: planejamentoTributario?.tributaria || "Não informado",
            },
            {
                type: "checkbox",
                label: "A empresa já realizou alguma espécie de auditoria digital | compliance?",
                value: auditoriaDigital?.activeSim ? ["Sim"] : ["Não"],
            },
            {
                type: "text",
                label: "A empresa já realizou alguma espécie de auditoria digital | compliance? (Comentário)",
                value: auditoriaDigital?.digital || "Não informado",
            },
            {
                type: "checkbox",
                label: "Aceitou o Termo de Confidencialidade",
                value: ["Sim"],
            },
            // {
            //     type: "checkbox",
            //     label: "Autorizo Termo de Confidencialidade",
            //     value: ["Sim"],
            // },
            {
                type: "text",
                label: "Observações",
                value: outrasObservacoes || "Não informado",
            },
        ],
    };

    // const getQuestionario = () => {
    //     setLoading(true);
    //     axios
    //         .post(
    //             `https://beta.ffadigital.com.br/api/external/project/questionnaire`,
    //             body
    //         )
    //         .then((response) => {
    //             navigate("/conclusao");
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             setLoading(false);
    //             toast.error(error.response.data.message);
    //             console.log(error);
    //         });

    // };

    return (
        <Container>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <Box>
                <Card>Questionário Preliminar</Card>

                <BoxStyle>
                    <Etapas />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            margin: " 0",
                        }}
                    >
                        <Progress
                            percent={100}
                            showInfo={false}
                            strokeColor={{
                                "0%": "#50c863",
                                "100%": "#50c863",
                            }}
                            trailColor={"#e5e5e5"}
                            style={{ width: "100%", marginBottom: "1rem", height: "0px" }}
                        />
                        <p>Progresso</p>
                    </div>

                    <CardComponent texto={"Outras observações?"} />

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            gap: "1rem",
                        }}
                    >
                        <Button active={activeSim} onClick={() => handleYesClick()}>
                            Sim
                        </Button>
                        <Button active={activeNao} onClick={() => handleNoClick()}>
                            Não
                        </Button>
                    </div>
                    {activeSim && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                gap: "1rem",
                                marginTop: "1rem",
                            }}
                        >
                            SE POSSÍVEL COMENTE:
                            <textarea
                                style={{
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: "6px",
                                    border: "none",
                                    padding: "10px",
                                    boxSizing: "border-box",
                                    backgroundColor: "white",
                                    fontSize: "18px",
                                }}
                                placeholder={"Digite seu comentário..."}
                                value={outrasObservacoes}
                                onChange={(e) => setOutrasObservacoes(e.target.value)}
                            />
                        </div>
                    )}

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            gap: "1rem",
                            marginTop: "1rem",
                        }}
                    >
                        <Checkbox
                            checked={termoConfidencialidade}
                            onChange={(e) => setTermoConfidencialidade(e.target.checked)}
                            style={{ color: "white" }}
                        >
                            Ao avançar, você concorda com o
                            <a href={process.env.REACT_APP_API_URL + '/preencher/questionario/projeto/'+ project_id +'/termo'} target='_blank' rel="noreferrer">
                                TERMO DE CONFIDENCIALIDADE
                            </a>
                        </Checkbox>
                    </div>
                    <ButtonComponent
                        onClick={
                            () => {
                                const prefix = pathPrefix()

                                navigate(
                                    {
                                        pathname: prefix + '/salvando',
                                        search: queryParams.toString()
                                    },
                                    {
                                        state: { body }
                                    }
                                )
                            }
                        }
                        text={"FINALIZAR"}
                        icon={<BsArrowRight />}
                    />
                </BoxStyle>
            </Box>
        </Container>
    );
}
