import {
    Box,
    BoxStyle,
    Card,
    CardCenter,
    CardImportante,
    Container,
    ImportantInfo,
} from "./style";
import ButtonComponent from "../../common/component/Button";
import { BsArrowRight } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { BiMessageError } from "react-icons/bi";
import {pathPrefix} from "../../data/pathPrefix";
import useQuery from "../../hooks/useQuery";

export default function Step04() {
    const navigate = useNavigate();
    const location = useLocation();
    const { project_id, regimeTributario } = location?.state || {};

    const queryParams = useQuery()

    return (
        <Container>
            <Box>
                <Card>Análise preliminar</Card>
                <BoxStyle>
                    <CardCenter>
                        <CardImportante>
                            <BiMessageError />
                            <h3>IMPORTANTE !!!</h3>
                        </CardImportante>
                    </CardCenter>
                    <ImportantInfo>
                        <h3>
                            A Recuperação de Créditos Tributários é um direito do(s)
                            contribuinte(s) PF e PJ assegurado em lei pela CF/88 -
                            Constituição Federal, (Art. 150, § 7º,) e no CTN - Código
                            Tributário Nacional, (Art. 165).
                        </h3>
                        <h3>
                            A Secretaria da Receita Federal do Brasil regulamenta a matéria na
                            Instrução Normativa RFB Nº. 2055, de 06 dezembro de 2021, com
                            alterações pelo(a) Instrução Normativa RFB nº. 2156, de 08 de
                            agosto de 2023.
                        </h3>
                        <h3>
                            Empresário, vamos juntos melhorar significativamente os resultados
                            fiscais, tributários e financeiros da sua empresa ?
                        </h3>
                    </ImportantInfo>

                    <ButtonComponent
                        onClick={
                            () => {
                                const prefix = pathPrefix()
                                navigate(
                                    {
                                        pathname: prefix + '/informe/tributario',
                                        search: queryParams.toString()
                                    },
                                    {
                                        state: { project_id }
                                    }
                                )
                            }
                        }
                        text={"INICIAR RECUPERAÇÃO"}
                        icon={<BsArrowRight />}
                    />
                </BoxStyle>
            </Box>
        </Container>
    );
}
